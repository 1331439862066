import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faSearch,
  faClock,
  faUser,
  faCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Accordion, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AaasStudentNav from "../Pages/AaasStudentNav";
import SDSdebar from "../Pages/SDSdebar";
import { DataGrid } from "@mui/x-data-grid";

const ExamList = () => {
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [registeredExams, setRegisteredExams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigateToExamDetails = (examId) => {
    const userId = localStorage.getItem("user_id");
    localStorage.setItem("exam_id", examId); // Set exam_id in local storage
    navigate(
      `/scheduleExam/ongongExam/sexamDetails?exam_id=${examId}&user_id=${userId}`
    );
  };

  useEffect(() => {
    const fetchExams = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://newaaas.tdtlworld.com/upcomingexam/",
          {
            params: {
              user_id: localStorage.getItem("user_id"),
            },
          }
        );
        if (response.data && response.data.exams) {
          setExams(response.data.exams);
        }
      } catch (error) {
        setError("No upcoming exams for you ");
      }
      setLoading(false);
    };

    fetchExams();
  }, []);

  const columns = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "start_date", headerName: "Start Date", width: 200 },
    { field: "marks", headerName: "Marks", width: 120 },
    { field: "duration", headerName: "Duration", width: 150 },
    // {
    //   field: "instructors",
    //   headerName: "Instructors",
    //   width: 250,
    //   sortable: false,
    // },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <button
          style={{
            padding: "8px 16px",
            fontSize: "14px",
            backgroundColor: "#007BFF",
            color: "#ffffff",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "background-color 0.3s ease-in-out",
          }}
          onClick={() => navigateToExamDetails(params.row.exam_id)}
        >
          View Exam
        </button>
      ),
    },
  ];

  const rows = exams.map((exam, index) => ({
    id: index,
    title: exam.title,
    start_date: exam.start_date,
    marks: exam.marks,
    duration: exam.duration,
    // Full_names: exam.Full_names,
    exam_id: exam.exam_id,
  }));

  const filteredRows = rows.filter(
    (row) =>
      row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.start_date.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    overflow: "hidden",
    width: "300px",
    marginBottom: "20px",
  };

  const searchIconStyle = {
    padding: "10px",
    backgroundColor: "#4d90fe",
    color: "white",
    border: "none",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  };

  const searchInputStyle = {
    flex: "1",
    padding: "8px", // Adjust the padding as needed
    border: "none",
    borderRadius: "4px 0 0 4px",
    fontSize: "12px", // Adjust the font size as needed
    // minWidth: "150px",
  };
  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div
          className="main p-3"
          style={{
            background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
          }}
        >
          <div
            className="container p-4"
            style={{
              maxWidth: "1000px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s ease-in-out",
              borderRadius: "10px",
              background: "#fff",
              overflow: "hidden",
            }}
          >
            <h2 style={{ color: "black" }}>Upcoming Exams</h2>
            <div style={searchBarStyle}>
              <FontAwesomeIcon icon={faSearch} style={searchIconStyle} />
              <input
                type="text"
                placeholder="Search by Exam Name or Date"
                className="w-50"
                style={searchInputStyle}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* <div className="input-group mb-3">
              <span className="input-group-text" id="search-icon">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search by Exam Name or Date"
                aria-label="Search"
                aria-describedby="search-icon"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div> */}
            {loading && <div className="text-center">Loading...</div>}
            {error && <div className="text-danger">{error}</div>}
            <div
              style={{ height: 400, width: "100%", backgroundColor: "white" }}
            >
              <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={5}
                checkboxSelection={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamList;
