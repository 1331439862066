import React, { useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  Badge,
  ProgressBar,
  Button,
  Form,
  Table,
  Modal,
  Container,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";

import {
  BsLightningFill,
  BsAlarm,
  BsChatDots,
  BsPerson,
  BsCalendar,
  BsClock,
  BsClockHistory,
  BsBook,
  BsTrophy,
  BsBell,
  BsChatFill,
  BsPeople,
  BsAward,
  BsGraphUp,
} from "react-icons/bs";
import { motion } from "framer-motion";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import Chart from "react-apexcharts";
import { PieChart } from "react-minimal-pie-chart";
import { useNavigate } from "react-router-dom";
import Rating from "react-rating-stars-component";
import {
  faUser,
  faList,
  faShieldAlt,
  faBell,
  faColumns,
  faTrophy,
  faStar,
  faCog,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
const StudentDashboard = () => {
  const navigate = useNavigate();

  const [full_name, setFullName] = useState("");
  const [role, setRole] = useState("");
  const [photo, setPhoto] = useState("");
  const [studentInfo, setStudentInfo] = useState({
    full_name: "",
    role: "",
    photo: "",
  });
  const [examResults, setExamResults] = useState([]); // Move it here
  const [upcomingExams, setUpcomingExams] = useState([]);

  const navigateToExamDetails = (examId) => {
    const userId = localStorage.getItem("user_id");
    localStorage.setItem("exam_id", examId); // Set exam_id in local storage
    navigate(
      `/scheduleExam/ongongExam/sexamDetails?exam_id=${examId}&user_id=${userId}`
    );
  };

  useEffect(() => {
    const storedFullName = localStorage.getItem("full_name");
    const storedRole = localStorage.getItem("role");
    const storedPhoto = localStorage.getItem("photo");
    if (storedFullName && storedRole && storedPhoto) {
      setFullName(storedFullName);
      setRole(storedRole);
      setPhoto(storedPhoto);
      setStudentInfo({
        full_name: storedFullName,
        role: storedRole,
        photo: storedPhoto,
      });
      fetchExamResults(storedRole); // Pass the role here
    }
  }, []);

  const fetchExamResults = async () => {
    try {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        console.error("User ID not found in local storage");
        return;
      }
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/exam_title/",
        {
          user_id: userId,
        }
      );
      if (response.status === 200) {
        const { latest_exam_results, upcoming_exams } = response.data.data;
        setExamResults(latest_exam_results);
        setUpcomingExams(upcoming_exams);
      } else {
        console.error("Failed to fetch exam results");
      }
    } catch (error) {
      console.error("Error fetching exam results:", error);
    }
  };

  // const studentInfo = {
  //   fullName: fullName,
  //   role: role,
  //   photo: photo,
  // };

  const performanceData = [
    { title: "Correct", value: 75, color: "#4CAF50" },
    { title: "Incorrect", value: 25, color: "#FF5733" },
  ];

  const testHistory = [
    { name: "Maths Exam", score: 99 },
    { name: "History Test", score: 35 },
    { name: "Dancing Test", score: 50 },
  ];

  const learningResources = [
    { name: "Mathematics Tutorial", link: "https://example.com/math-tutorial" },
    {
      name: "Science Resources",
      link: "https://example.com/science-resources",
    },
    { name: "History Tutorial", link: "https://example.com/math-tutorial" },
    {
      name: "Dancing Resources",
      link: "https://example.com/science-resources",
    },
  ];
  const calculateMarksExploration = () => {
    if (examResults.length === 0) return { average: 0, highest: 0, lowest: 0 };

    const marks = examResults.map((result) => result.percentage);
    const total = marks.reduce((acc, mark) => acc + mark, 0);
    const average = total / marks.length;
    const highest = Math.max(...marks);
    const lowest = Math.min(...marks);

    return { average, highest, lowest };
  };

  const { average, highest, lowest } = calculateMarksExploration();

  const calculateProgressData = (results) => {
    const subjects = {};
    results.forEach((result) => {
      if (!subjects[result.exam_title]) {
        subjects[result.exam_title] = {
          total: 0,
          count: 0,
        };
      }
      subjects[result.exam_title].total += result.percentage;
      subjects[result.exam_title].count += 1;
    });

    return Object.keys(subjects).map((subject) => ({
      label: subject,
      value: subjects[subject].total / subjects[subject].count,
    }));
  };
  const progressData = calculateProgressData(examResults);

  const notifications = [
    "Reminder: Math Test tomorrow at 10:00 AM",
    "New Learning Resource available for History",
  ];

  const achievements = [
    { name: "Top Performer", date: "2024-01-31" },
    { name: "Completionist", date: "2024-02-01" },
  ];

  const pieChartData = [
    { title: "1st Test", value: 30, color: "#FF5733" },
    { title: "2nd Test", value: 20, color: "#4CAF50" },
    { title: "3rd Test", value: 50, color: "#2196F3" },
  ];

  const recommendationsData = [
    "Improve time management during exams.",
    "Focus on understanding complex concepts in Math.",
    "Practice more on essay writing for English exams.",
  ];

  const feedbackData = [
    "Received positive feedback on your recent Math assignment.",
    "Peer suggestion: Consider using more examples in your answers.",
    "Great job on the Science project! Collaborate more with classmates.",
  ];

  const leaderboardData = [
    { name: "Code Ranger Exam 1", score: 95 },
    { name: "CodeWthAnant Exam 2", score: 19 },
    { name: "Apti Expert Exam 3", score: 49 },
    { name: "Super Coder Exam 4", score: 59 },
  ];

  const chartData = leaderboardData.map((entry) => ({
    x: entry.score,
    y: entry.name,
  }));

  const chartOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Score"],
    },
  };

  const [feedback, setFeedback] = React.useState("");
  const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);

  const handleFeedbackSubmit = () => {
    setShowFeedbackModal(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div style={{ marginTop: "60px", fontFamily: "'Roboto', sans-serif" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div
          className="main p-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "20px",
          }}
        >
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              gridColumn: "1 / -1",
              fontFamily: "Arial, sans-serif",
              fontSize: "2rem",
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              color: "#4e73df",
            }}
          >
            Welcome, <span>{studentInfo.full_name}!</span>
          </motion.h2>

          {/* Profile Information Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsPerson /> &nbsp;Profile Information
            </Card.Header>
            <ListGroup variant="flush" className="flex-grow-1">
              <ListGroup.Item style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <img
                    src={studentInfo.photo}
                    alt="Profile"
                    className="rounded-circle"
                    style={{ width: "80px", height: "80px" }}
                  />
                </div>
                <div>
                  <strong>Name:</strong> {studentInfo.full_name}
                  <br />
                  <strong>Id:</strong> {studentInfo.role}
                </div>
              </ListGroup.Item>
            </ListGroup>
            <div className="text-center p-2">
              <Rating
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<FontAwesomeIcon icon={farStar} />}
                halfIcon={<FontAwesomeIcon icon={faStar} />}
                fullIcon={<FontAwesomeIcon icon={faStar} />}
                activeColor="#ffd700"
                value={4.5}
              />
            </div>
          </Card>

          {/* Upcoming Exams Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsAlarm /> &nbsp;Upcoming Exams
            </Card.Header>
            <ListGroup variant="flush" className="flex-grow-1">
              {upcomingExams.length > 0 ? (
                upcomingExams.map((exam, index) => (
                  <ListGroup.Item
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <strong>{exam.title}</strong>
                      <br />
                      {exam.start_date} at {exam.duration}
                    </div>
                    <Button
                      variant="info"
                      onClick={() => navigateToExamDetails(exam.exam_id)}
                    >
                      Join Test
                    </Button>
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>
                  <Alert variant="info">
                    <strong>No upcoming exams found.</strong> Please register
                    for exams to start participating.
                  </Alert>
                  <Button
                    variant="primary"
                    href="/scheduleExam/ongongExam"
                    rel="noopener noreferrer"
                  >
                    Get Register
                  </Button>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>

          {/* Test History Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsClockHistory /> &nbsp;Test History
            </Card.Header>
            {/* <ListGroup variant="flush" className="flex-grow-1">
              {examResults.map((result, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <strong>{result.exam_title}</strong>
                    <br />
                    Percentage: {result.percentage}%
                  </div>
                  <div style={{ fontSize: "24px", margin: "0 10px" }}>
                    {result.pass_status === "Pass" ? "😄" : "😢"}
                  </div>
                  <Badge
                    variant={
                      result.pass_status === "Pass" ? "success" : "danger"
                    }
                  >
                    {result.pass_status}
                  </Badge>
                </ListGroup.Item>
              ))}
            </ListGroup> */}
          </Card>

          {/* Learning Resources Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsBook /> &nbsp;Learning Resources
            </Card.Header>
            <ListGroup variant="flush" className="flex-grow-1">
              {learningResources.map((resource, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <a
                      href={resource.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <strong>{resource.name}</strong>
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    href={resource.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Explore
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          {/* Marks Exploration Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsBook /> &nbsp;Marks Exploration
            </Card.Header>
            {/* <ListGroup variant="flush" className="flex-grow-1">
              <ListGroup.Item>
                <div>
                  <strong>Average Marks:</strong> {average.toFixed(2)}%
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>
                  <strong>Highest Marks:</strong> {highest}%
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div>
                  <strong>Lowest Marks:</strong> {lowest}%
                </div>
              </ListGroup.Item>
            </ListGroup> */}
          </Card>
          {/* Achievements Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsTrophy /> &nbsp;Achievements
            </Card.Header>
            <ListGroup variant="flush" className="flex-grow-1">
              {achievements.map((achievement, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <Badge variant="success">{achievement.name}</Badge> -
                    Achieved on {achievement.date}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          {/* Notifications Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsBell /> &nbsp;Notifications
            </Card.Header>
            <ListGroup variant="flush" className="flex-grow-1">
              {notifications.map((notification, index) => (
                <ListGroup.Item key={index}>
                  <Alert variant="info">{notification}</Alert>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          {/* Progress Tracker */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsGraphUp /> &nbsp;Progress Tracker
            </Card.Header>
            {/* <ListGroup variant="flush" className="flex-grow-1">
              {progressData.map((data, index) => (
                <ListGroup.Item
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <strong>{data.label}</strong>
                  </div>
                  <ProgressBar
                    animated
                    now={data.value}
                    label={`${Math.round(data.value)}%`}
                    style={{ width: "70%" }}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup> */}
          </Card>

          {/* Leaderboard Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              Leaderboard
            </Card.Header>
            <div className="p-3">
              <Chart
                options={chartOptions}
                series={[{ data: chartData }]}
                type="bar"
                height={230}
              />
            </div>
          </Card>

          {/* Recommendations for Improvement */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsLightningFill /> &nbsp;Recommendations for Improvement
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <ul>
                {recommendationsData.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>

          {/* Performance Analytics */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              <BsGraphUp /> &nbsp;Performance Analytics
            </Card.Header>
            <div className="text-center p-2 flex-grow-1 d-flex align-items-center justify-content-center">
              <PieChart
                data={performanceData}
                label={({ dataEntry }) =>
                  `${Math.round(dataEntry.percentage)}%`
                }
                labelStyle={{
                  fontSize: "10px",
                  fontFamily: "sans-serif",
                  fill: "#fff",
                }}
                style={{ width: "200px", height: "200px" }}
                animate
              />
            </div>
          </Card>

          {/* Feedback Section */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header
              className="text-center text-white"
              style={{ background: "#4e73df" }}
            >
              Feedback
            </Card.Header>
            <Card.Body className="d-flex flex-column align-items-center flex-grow-1">
              <Form className="w-100">
                <Form.Group controlId="feedbackTextArea" className="mb-3">
                  <Form.Label className="font-weight-bold">
                    Your Feedback:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={handleFeedbackSubmit}
                  className="w-100"
                >
                  Submit Feedback
                </Button>
              </Form>
            </Card.Body>
          </Card>

          {/* Awards and Points */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header className="text-center bg-warning text-white">
              <BsAward /> &nbsp;Awards and Points
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <div className="awards-list">
                <div className="award-item">
                  <BsAward className="award-icon gold" />
                  Gold Medal - Mathematics
                </div>
                <div className="award-item">
                  <BsAward className="award-icon silver" />
                  Silver Medal - Science
                </div>
                <div className="award-item">
                  <BsAward className="award-icon bronze" />
                  Bronze Medal - Coding
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Social Features */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header className="text-center bg-info text-white">
              <BsPeople /> &nbsp;Social Features
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <div>
                <h5>Recent Discussions</h5>
                <ul>
                  <li>
                    <strong>Topic:</strong> React Development
                  </li>
                  <li>
                    <strong>Topic:</strong> Machine Learning Algorithms
                  </li>
                  <li>
                    <strong>Topic:</strong> Coding Challenges
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>

          {/* Feedback from Peers */}
          <Card
            className="shadow-sm"
            style={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Card.Header className="text-center bg-primary text-white">
              <BsChatDots /> &nbsp;Feedback from Peers
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <ul>
                {feedbackData.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
