import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Confetti from "react-confetti";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { FaThumbsUp, FaSmile } from "react-icons/fa";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function Congratulations() {
  const [showConfetti, setShowConfetti] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [examResult, setExamResult] = useState(null);

  useEffect(() => {
    const examSubmitted = true;
    if (examSubmitted) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000000000000); // Adjust the confetti duration
      submitExamResults();
    }
  }, []);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleFeedbackSubmit = () => {
    setFeedbackSubmitted(true);
  };

  const submitExamResults = async () => {
    try {
      const examId = localStorage.getItem("exam_id");
      const userId = localStorage.getItem("user_id");

      const response = await axios.post(
        "https://newaaas.tdtlworld.com/result/",
        {
          exam_id: examId,
          user_id: userId,
        }
      );
      console.log(response.data);
      setExamResult(response.data);
    } catch (error) {
      console.error("Error submitting exam results:", error);
    }
  };

  const progressBarWidth = examResult
    ? (examResult.marks / examResult.total_marks) * 100
    : 0;

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div
        className="wrapper"
        style={{
          background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
        }}
      >
        <SDSdebar />
        <div className="main p-3" style={styles.container}>
          <div>
            <h1 style={styles.header}>Congratulations! 🎉</h1>
            {showConfetti && (
              <Confetti width={window.innerWidth} height={window.innerHeight} />
            )}
            <p style={styles.text}>
              Your exam has been submitted successfully! <FaThumbsUp />
            </p>
            {/* <p>
              Total Marks: {examResult ? examResult.total_marks : "Loading..."}{" "}
              <br />
              Marks: {examResult ? examResult.marks : "Loading..."} <br />
              Percentage: {examResult ? examResult.percentage : "Loading..."}
            </p>
            <div style={styles.progressContainer}>
              <div style={styles.progressLabel}>Marks Obtained</div>
              <ProgressBar
                animated
                now={progressBarWidth}
                label={`${progressBarWidth}%`}
                style={styles.animatedProgressBar}
              />
            </div> */}
          </div>
          <div style={styles.feedbackSection}>
            <h2 style={styles.feedbackHeader}>
              Feedback <FaSmile />
            </h2>
            {feedbackSubmitted ? (
              <p style={styles.thankYouMessage}>
                Thank you for your feedback! 😊
              </p>
            ) : (
              <div>
                <textarea
                  rows="4"
                  cols="50"
                  style={styles.feedbackInput}
                  placeholder="Provide your feedback... 📝"
                  value={feedback}
                  onChange={handleFeedbackChange}
                ></textarea>
                <button
                  style={{
                    ...styles.feedbackSubmitButton,
                    opacity: feedback ? "1" : "0.5",
                  }}
                  onClick={handleFeedbackSubmit}
                  disabled={!feedback}
                >
                  Submit Feedback
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#f7f7f7",
    borderRadius: "10px",
    padding: "20px",
    textAlign: "center",
    maxWidth: "600px",
    margin: "auto",
  },
  header: {
    fontSize: "2em",
    color: "#3e4095",
  },
  text: {
    fontSize: "1.2em",
    color: "black",
    fontWeight: "bold",
  },

  feedbackSection: {
    marginTop: "20px",
  },
  feedbackHeader: {
    fontSize: "1.5em",
    color: "#3e4095",
  },
  feedbackInput: {
    width: "100%",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  feedbackSubmitButton: {
    backgroundColor: "#3e4095",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
    transition: "background-color 0.3s ease",
    border: "none",
    outline: "none",
    fontSize: "1em",
  },
  thankYouMessage: {
    fontSize: "1.2em",
    color: "#3e4095",
  },
  progressContainer: {
    marginTop: "20px",
  },
  progressLabel: {
    fontSize: "1.2em",
    color: "#3e4095",
    marginBottom: "10px",
  },
  animatedProgressBar: {
    animation: "pulse 2s infinite",
  },
};
