import React, { useState } from "react";
import "./Sidebar.css";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faUserGraduate,
  faUsers,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddCategory = () => {
    fetch("https://newaaas.tdtlworld.com/add_category/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category_name: categoryName }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Category added successfully");
          Swal.fire("Success!", "Category added successfully!", "success");
          closeModal();
        } else {
          throw new Error("Failed to add category");
        }
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        // Handle error as needed
      });
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="dashboard-card">
              <Card.Body>
                <Button
                  variant="primary"
                  className="mb-3"
                  onClick={openModal}
                  style={{ width: "200px", marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Category
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="categoryName">Category Name:</label>
              <input
                type="text"
                className="form-control"
                id="categoryName"
                name="categoryName"
                placeholder="Enter category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
            {/* You can add more form fields here */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} /> Close
          </Button>
          <Button variant="primary" onClick={handleAddCategory}>
            <FontAwesomeIcon icon={faPlus} /> Add Category
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCategory;
