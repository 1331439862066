import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import bregster from "../Pages/bregster.png";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const RegistrationForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleRegister = async () => {
    if (!name || !email || !password || !confirmPassword) {
      alert("All fields are required.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      const registrationData = {
        name,
        email,
        password,
        role: "admin", // Hardcoded role as admin
      };

      const response = await axios.post(
        "https://newaaas.tdtlworld.com/registration/",
        registrationData
      );

      if (response.data.success) {
        setRegistrationSuccess(true);
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      alert("An error occurred during registration.");
    }
  };

  return (
    <div className="container-fluid ">
      <div className="row p-4">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>

        <div className="col-md-5">
          <div className="main p-4 rounded shadow bg-light">
            <div className="card-body">
              {registrationSuccess ? (
                <div className="alert alert-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  Thank you for registration! You're now part of our community.
                  🎉
                </div>
              ) : (
                <form>
                  <div className="mb-1">
                    <div
                      className="text-center mb-1"
                      style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                    >
                      Admin Register
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-0">
                        <label htmlFor="name" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 ">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-0">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-0">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-1 mt-4">
                    <button
                      type="button"
                      className="btn btn-dark btn-block"
                      style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                      onClick={handleRegister}
                    >
                      Admin Register
                    </button>
                  </div>
                </form>
              )}
              <div className="text-center mt-0">
                Already have an account?{" "}
                <Link to="/Admnlogn">
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
                  Login here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
