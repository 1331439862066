import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowRight,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import bregster from "../Pages/bregster.png";
import { Vortex } from "react-loader-spinner";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Student");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/login/",
        {
          email: email,
          password: password,
          role: selectedTab.toLowerCase(),
        }
      );

      if (response.data.success === "Login successful") {
        const { user_id, full_name, email, role, photo } = response.data;
        localStorage.setItem("full_name", full_name);
        localStorage.setItem("email", email);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("password", password);
        localStorage.setItem("role", role);
        localStorage.setItem("photo", photo);

        setLoginSuccess(true);

        if (role == 2) {
          navigate("/logn/aaasDashboard");
        } else {
          navigate("/logn/aaasStudentDashboard");
        }

        Swal.fire({
          icon: "success",
          title: `Welcome to Aaas, ${full_name}!`,
          text: "We are glad you are here.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Please check your credentials and try again.",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please check your credentials.",
      });
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      errors.email = "Invalid email address.";
    }

    if (!password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>
        <div className="col-md-5 d-flex justify-content-center ">
          <div className="main p-4 rounded shadow bg-light">
            <div style={{ maxWidth: "450px", margin: "0 auto" }}>
              <div
                className="text-center mb-1"
                style={{
                  color: "dodgerblue",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
              >
                {selectedTab} Login
              </div>
              <div
                className="text-center mb-3"
                style={{ fontWeight: "bold", fontSize: "1.3rem" }}
              >
                Choose your role
              </div>
              <div className="btn-group d-flex text-center mb-2 px-4">
                <button
                  type="button"
                  className={`btn ${
                    selectedTab === "Student" ? "btn-dark" : "btn-secondary"
                  }`}
                  onClick={() => handleTabChange("Student")}
                  style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                >
                  Student
                </button>
                <button
                  type="button"
                  className={`btn ${
                    selectedTab === "Instructor" ? "btn-dark" : "btn-secondary"
                  }`}
                  onClick={() => handleTabChange("Instructor")}
                  style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                >
                  Instructor
                </button>
              </div>
              {loginSuccess && (
                <div className="alert alert-success">Login successful!</div>
              )}
            </div>
            <div style={{ maxWidth: "400px", margin: "0 auto" }}>
              <div className="mb-3 mt-4">
                <label
                  htmlFor="email"
                  className="form-label"
                  style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                >
                  Email&nbsp;<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label"
                  style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                >
                  Password&nbsp;<span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={togglePasswordVisibility}
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <button
                    type="button"
                    className="btn btn-dark btn-block"
                    onClick={handleLogin}
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <Vortex height="40" width="40" color="#fff" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </div>
              <div>
                <p className="mb-0 ">
                  <Link
                    to="/forgotpassword"
                    className="btn btn-outline-dark btn-block"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    Forgot Password
                  </Link>
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#333",
                    marginBottom: "10px",
                  }}
                >
                  Don't have an account?{" "}
                </p>
                <Link
                  to="/regster"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#007bff",
                    textDecoration: "none",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#0056b3")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#007bff")
                  }
                >
                  Click for Registration
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
// import React, { useState } from "react";
// import Swal from "sweetalert2";
// import { Link, useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faEye,
//   faArrowRight,
//   faEyeSlash,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import { Vortex } from "react-loader-spinner";
// // import videoSrc from "../assets/background.mp4";
// import videoSrc from "./aaaaaas.mp4";
// const Login = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [loginSuccess, setLoginSuccess] = useState(false);
//   const [selectedTab, setSelectedTab] = useState("Student");
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);

//   const handleLogin = async () => {
//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/login/",
//         {
//           email: email,
//           password: password,
//           role: selectedTab.toLowerCase(),
//         }
//       );

//       if (response.data.success === "Login successful") {
//         const { user_id, full_name, email, role, photo } = response.data;
//         localStorage.setItem("full_name", full_name);
//         localStorage.setItem("email", email);
//         localStorage.setItem("user_id", user_id);
//         localStorage.setItem("password", password);
//         localStorage.setItem("role", role);
//         localStorage.setItem("photo", photo);

//         setLoginSuccess(true);

//         if (role === "instructor") {
//           navigate("/logn/aaasDashboard");
//         } else {
//           navigate("/logn/aaasStudentDashboard");
//         }

//         Swal.fire({
//           icon: "success",
//           title: `Welcome to Aaas, ${full_name}!`,
//           text: "We are glad you are here.",
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Login Failed",
//           text: "Please check your credentials and try again.",
//         });
//       }
//     } catch (error) {
//       console.error("Error during login:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Please check your credentials.",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleTabChange = (tab) => {
//     setSelectedTab(tab);
//     setErrors({});
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!email) {
//       errors.email = "Email is required.";
//     } else if (!validateEmail(email)) {
//       errors.email = "Invalid email address.";
//     }

//     if (!password) {
//       errors.password = "Password is required.";
//     }

//     return errors;
//   };

//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         width: "100%",
//         height: "100vh",
//         overflow: "hidden",
//       }}
//     >
//       <video
//         autoPlay
//         loop
//         muted
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//           zIndex: -1,
//         }}
//       >
//         <source src={videoSrc} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//       <div
//         style={{
//           position: "relative",
//           zIndex: 1,
//           width: "100%",
//           height: "100%",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             padding: "20px",
//             borderRadius: "10px",
//             background: "rgba(255, 255, 255, 0.8)",
//             boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
//             maxWidth: "400px",
//             width: "100%",
//           }}
//         >
//           <div className="text-center mb-4">
//             <h2 style={{ color: "dodgerblue", fontWeight: "bold" }}>
//               {selectedTab} Login
//             </h2>
//             <p style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
//               Choose your role
//             </p>
//             <div className="btn-group d-flex">
//               <button
//                 type="button"
//                 className={`btn ${
//                   selectedTab === "Student"
//                     ? "btn-primary"
//                     : "btn-outline-primary"
//                 }`}
//                 onClick={() => handleTabChange("Student")}
//               >
//                 Student
//               </button>
//               <button
//                 type="button"
//                 className={`btn ${
//                   selectedTab === "Instructor"
//                     ? "btn-primary"
//                     : "btn-outline-primary"
//                 }`}
//                 onClick={() => handleTabChange("Instructor")}
//               >
//                 Instructor
//               </button>
//             </div>
//           </div>
//           {loginSuccess && (
//             <div className="alert alert-success text-center">
//               Login successful!
//             </div>
//           )}
//           <div>
//             <div className="mb-3">
//               <label htmlFor="email" className="form-label">
//                 Email&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="email"
//                 className={`form-control ${errors.email ? "is-invalid" : ""}`}
//                 id="email"
//                 placeholder="Enter your email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               {errors.email && (
//                 <div className="invalid-feedback">{errors.email}</div>
//               )}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="password" className="form-label">
//                 Password&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <div className="input-group">
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   className={`form-control ${
//                     errors.password ? "is-invalid" : ""
//                   }`}
//                   id="password"
//                   placeholder="Enter Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-outline-secondary"
//                   onClick={togglePasswordVisibility}
//                 >
//                   <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
//                 </button>
//                 {errors.password && (
//                   <div className="invalid-feedback">{errors.password}</div>
//                 )}
//               </div>
//             </div>
//             <div className="d-grid mb-3">
//               <button
//                 type="button"
//                 className="btn btn-primary"
//                 onClick={handleLogin}
//                 disabled={loading}
//               >
//                 {loading ? (
//                   <Vortex height="20" width="20" color="#fff" />
//                 ) : (
//                   "Login"
//                 )}
//               </button>
//             </div>
//             <div className="text-center mb-3">
//               <Link to="/forgotpassword" className="text-primary">
//                 Forgot Password?
//               </Link>
//             </div>
//             <div className="text-center">
//               <p>
//                 Don't have an account?{" "}
//                 <Link to="/regster" className="text-primary">
//                   Click for Registration
//                   <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
//                 </Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;
