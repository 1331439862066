import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowRight,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import bregster from "../Pages/bregster.png";
import { Vortex } from "react-loader-spinner";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Student");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/login/",
        {
          email: email,
          password: password,
          role: selectedTab.toLowerCase(),
        }
      );

      if (response.data.success === "Login successful") {
        const { user_id, full_name, email, role, photo } = response.data;
        localStorage.setItem("full_name", full_name);
        localStorage.setItem("email", email);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("password", password);
        localStorage.setItem("role", role);
        localStorage.setItem("photo", photo);

        setLoginSuccess(true);

        navigate("/AdmnDashboard");

        Swal.fire({
          icon: "success",
          title: `Welcome to Aaas, ${full_name}!`,
          text: "We are glad you are here.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Please check your credentials and try again.",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      errors.email = "Invalid email address.";
    }

    if (!password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>
        <div className="col-md-5 d-flex justify-content-center ">
          <div className="main p-4 rounded shadow bg-light">
            <div style={{ maxWidth: "450px", margin: "0 auto" }}>
              <div
                className="text-center mb-1"
                style={{
                  color: "dodgerblue",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
              >
                Admin Login
              </div>

              {loginSuccess && (
                <div className="alert alert-success">Login successful!</div>
              )}
            </div>
            <div style={{ maxWidth: "400px", margin: "0 auto" }}>
              <div className="mb-3 mt-4">
                <label
                  htmlFor="email"
                  className="form-label"
                  style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                >
                  Email
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label"
                  style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                >
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={togglePasswordVisibility}
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <button
                    type="button"
                    className="btn btn-dark btn-block"
                    onClick={handleLogin}
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                    disabled={loading}
                  >
                    {loading ? (
                      <Vortex height="40" width="40" color="#fff" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </div>
              <div>
                <p className="mb-0 ">
                  <Link
                    to="/forgotpassword"
                    className="btn btn-outline-dark btn-block"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    Forgot Password
                  </Link>
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#333",
                    marginBottom: "10px",
                  }}
                >
                  Don't have an account?{" "}
                </p>
                <Link
                  to="/admnRegister"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#007bff",
                    textDecoration: "none",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#0056b3")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#007bff")
                  }
                >
                  Click for Registration
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ marginLeft: "8px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
