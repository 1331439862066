import React, { useEffect, useState } from "react";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faQuestion,
  faListAlt,
  faLayerGroup,
  faUsers,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { Bar, Pie } from "react-chartjs-2";
import { CategoryScale, Chart, registerables } from "chart.js";

Chart.register(...registerables, CategoryScale);

const AaasDashboard = () => {
  const [backendData, setBackendData] = useState(null);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(
        "https://newaaas.tdtlworld.com/fetch_dashboard/"
      );
      const data = await response.json();
      setBackendData(data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />
      <div className="wrapper" style={{ background: "#f7f8fc" }}>
        <Sidebar />
        <div className="main p-4" style={{ minHeight: "calc(100vh - 60px)" }}>
          <div className="text-center">
            <h2 className="mb-4">Aaas Dashboard</h2>
            <Row className="mb-4">
              {[
                {
                  icon: faGraduationCap,
                  label: "Total Exams",
                  value: backendData ? backendData.total_exams : "-",
                  color: "#ff8080",
                },
                {
                  icon: faQuestion,
                  label: "Total Questions",
                  value: backendData ? backendData.total_questions : "-",
                  color: "#cc99ff",
                },
                {
                  icon: faListAlt,
                  label: "Total Categories",
                  value: backendData ? backendData.total_categories : "-",
                  color: "#99ff99",
                },
                {
                  icon: faLayerGroup,
                  label: "Total Subcategories",
                  value: backendData ? backendData.total_subcategories : "-",
                  color: "#ffff80",
                },
                {
                  icon: faUsers,
                  label: "Total Users",
                  value: backendData ? backendData.total_users : "-",
                  color: "#ff9999",
                },
                {
                  icon: faHistory,
                  label: "Total Pass Students",
                  value: backendData ? backendData.total_pass_students : "-",
                  color: "#99ccff",
                },
              ].map((item, index) => (
                <Col key={index} md={4} className="p-2">
                  <motion.div
                    className="dashboard-card"
                    whileHover={{ scale: 1.05 }}
                    style={{
                      borderRadius: "20px",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                      background: item.color,
                      padding: "20px",
                      textAlign: "center",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <h4>
                      <FontAwesomeIcon icon={item.icon} /> {item.label}
                    </h4>
                    <motion.div
                      className="chart"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                    >
                      {item.value}
                    </motion.div>
                  </motion.div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={4}>
                {backendData && backendData.categories && (
                  <div className="mb-4">
                    <h4>Categories:</h4>
                    <Pie
                      data={{
                        labels: backendData.categories.map(
                          (category) => category.category_name
                        ),
                        datasets: [
                          {
                            label: "Categories",
                            data: backendData.categories.map(
                              (category) => category.total_questions
                            ),
                            backgroundColor: [
                              "#ff6384",
                              "#36a2eb",
                              "#ffce56",
                              "#ff8e76",
                              "#77dd77",
                            ],
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        aspectRatio: 1,
                      }}
                      width={400}
                      height={400}
                    />
                  </div>
                )}
              </Col>
              <Col md={4}>
                {backendData && backendData.questions_by_subcategory && (
                  <div className="mb-4">
                    <h4>Questions by Subcategory:</h4>
                    <Bar
                      data={{
                        labels: backendData.questions_by_subcategory.map(
                          (item) => item.sub_category
                        ),
                        datasets: [
                          {
                            label: "Total Questions",
                            data: backendData.questions_by_subcategory.map(
                              (item) => item.total_questions
                            ),
                            backgroundColor: "#36a2eb",
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        aspectRatio: 1,
                        indexAxis: "x", // Change to "x" for vertical bar chart
                        scales: {
                          x: {
                            beginAtZero: true,
                          },
                        },
                      }}
                      width={400}
                      height={400}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AaasDashboard;
