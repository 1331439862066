import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faStop,
  faPlus,
  faTrash,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Swal from "sweetalert2";

const SingleTypeMcqQuz = ({ category, sub_category, type }) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correct_option, setcorrect_option] = useState(null);
  const [submittedQuestions, setSubmittedQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [marks, setMarks] = useState(0);

  const { transcript, resetTranscript } = useSpeechRecognition();

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleMicClick = () => {
    SpeechRecognition.startListening();
    setIsListening(true);
  };

  const handleMicStopClick = () => {
    SpeechRecognition.stopListening();
    setQuestion(transcript);
    resetTranscript();
    setIsListening(false);
  };

  const handleSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      const quizData = {
        category,
        sub_category,
        type,
        description: question,
        options,
        correct_option:
          correct_option !== null ? options[correct_option] : null,
        marks,
        image: selectedFile ? selectedFile.url : null,
      };

      fetch("https://newaaas.tdtlworld.com/add_quiz_detail/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quizData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const submittedQuiz = {
            ...quizData,
            image: selectedFile,
          };
          setSubmittedQuestions([...submittedQuestions, submittedQuiz]);
          setQuestion("");
          setOptions(["", "", "", ""]);
          setcorrect_option(null);
          setMarks(0);
          setSelectedFile(null);
          setIsSubmitting(false);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Question submitted successfully.",
          });
        })
        .catch((error) => {
          console.error("Error submitting quiz:", error);
          setIsSubmitting(false);
        });
    }
  };

  const renderSubmittedQuestions = () => (
    <div className="bg-light p-4 mt-5 rounded">
      <h3 className="text-primary text-center mb-4">Submitted Questions</h3>
      <div>
        <p>category: {category}</p>
        <p>sub_category: {sub_category}</p>
        <p>Type: {type}</p>
      </div>
      {submittedQuestions.map((submittedQuiz, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{submittedQuiz.question}</h5>
            <ul className="list-group mb-3">
              {submittedQuiz.options.map((option, i) => (
                <li
                  key={i}
                  className={`list-group-item ${
                    submittedQuiz.correct_option === i
                      ? "list-group-item-success"
                      : ""
                  }`}
                >
                  {option}
                </li>
              ))}
            </ul>
            <p className="card-text">
              Correct Option: Option {submittedQuiz.correct_option}
            </p>
            <p className="card-text">Marks: {submittedQuiz.marks}/100</p>

            {submittedQuiz.image && (
              <div className="submitted-image-container mt-3">
                <p className="mb-2">Submitted Image:</p>
                <img
                  src={submittedQuiz.image.url}
                  alt="Submitted Image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const renderForm = () => (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="question"
          style={{ display: "block", textAlign: "start" }}
        >
          Question<span style={{ color: "red" }}>*</span>:
        </label>

        <div style={{ display: "flex", marginBottom: "10px" }}>
          <input
            type="text"
            className="form-control"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question..."
            style={{
              borderTopRightRadius: isListening ? "0" : "0.25rem",
              borderBottomRightRadius: isListening ? "0" : "0.25rem",
              marginRight: "-1px",
            }}
          />
          <button
            type="button"
            className={`btn ${isListening ? "btn-danger" : "btn-dark"}`}
            onClick={isListening ? handleMicStopClick : handleMicClick}
            style={{
              borderTopLeftRadius: "0.25rem",
              borderBottomLeftRadius: "0.25rem",
              borderTopRightRadius: isListening ? "0" : "0.25rem",
              borderBottomRightRadius: isListening ? "0" : "0.25rem",
            }}
          >
            <FontAwesomeIcon icon={isListening ? faStop : faMicrophone} />
          </button>
        </div>

        <div
          style={{
            border: "1px solid #ccc",
            marginTop: "10px",
            position: "relative",
          }}
          onClick={() => document.getElementById("coverPhoto").click()}
        >
          {selectedFile ? (
            <img
              src={selectedFile.url}
              alt="Selected Course Cover"
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "150px",
                backgroundColor: "#f0f0f0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#888", fontSize: "14px" }}>
                Click here to upload an Question image
              </span>
            </div>
          )}
          <input
            type="file"
            id="coverPhoto"
            name="coverPhoto"
            className="visually-hidden"
            onChange={handleFileInputChange}
            accept="image/*"
            style={{ position: "absolute", top: 0, left: 0, opacity: 0 }}
          />
        </div>
        <div className="col-md-3">
          <input
            type="number"
            className="form-control"
            placeholder="Add Mark"
            id="markOption"
            onChange={(e) => setMarks(Number(e.target.value))}
          />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", textAlign: "start" }}>
          Options<span style={{ color: "red" }}>*</span>:
        </label>
        {options.map((option, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              marginBottom: "10px",
              alignItems: "center",
              backgroundColor: "#f0f0f0",
              borderRadius: "4px",
              padding: "8px",
            }}
          >
            <input
              type="radio"
              className="form-check-input"
              checked={correct_option === index}
              onChange={() => handleMarkOption(index)}
            />
            <input
              type="text"
              className="form-control option-input"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder="Enter option..."
              style={{
                flex: "1",
                marginLeft: "8px",
                border: "none",
                boxShadow: "none",
              }}
            />
            <button
              type="button"
              className="btn btn-danger btn-remove-option"
              onClick={() => handleRemoveOption(index)}
              style={{ marginLeft: "8px" }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}

        <button
          type="button"
          className="btn btn-dark btn-add-option"
          onClick={handleAddOption}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Options
        </button>
      </div>

      <div style={{ textAlign: "start" }}>
        <button
          type="button"
          className="btn btn-success"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;Submitting...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCheck} /> Submit
            </>
          )}
        </button>
        {isSubmitting && (
          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={() => setIsSubmitting(false)}
          >
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
        )}
      </div>
    </div>
  );

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);

    // Adjust correct option index if it is removed
    if (correct_option === index + 1) {
      setcorrect_option(null);
    } else if (correct_option > index + 1) {
      setcorrect_option(correct_option - 1);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const url = window.URL.createObjectURL(file);
      setSelectedFile({
        file,
        url,
      });
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  const handleUploadImage = () => {
    document.getElementById("coverPhoto").click();
  };

  const handleMarkOption = (index) => {
    setcorrect_option(index);
  };

  return (
    <div>
      <div className="wrapper">
        <div className="main p-3">
          <div className="single-type-mcq-quiz-container container mt-3">
            {renderForm()}
            {/* {renderSubmittedQuestions()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleTypeMcqQuz;
