import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import Rating from "react-rating-stars-component"; // Import the Rating component
import Swal from "sweetalert2"; // Import SweetAlert

export default function SuggestionAndFeedback() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedback: "",
    satisfaction: "",
    user_id: "",
    submission_rating: 0, // Initial rating value
  });

  useEffect(() => {
    // Retrieve user_id and email from localStorage
    const user_id = localStorage.getItem("user_id");
    const email = localStorage.getItem("email");
    if (user_id && email) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: user_id,
        email: email,
      }));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleRatingChange = (newRating) => {
    setFormData({ ...formData, submission_rating: newRating });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/submit_feedback/",
        formData
      );
      console.log(response.data); // Log success message
      // Display success message using SweetAlert
      Swal.fire({
        icon: "success",
        title: "Feedback Submitted!",
        text: "Thank you for your feedback.",
      });
      // Optionally, reset form fields after successful submission
      setFormData({
        name: "",
        email: "",
        feedback: "",
        satisfaction: "",
        user_id: "",
        submission_rating: 0,
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      // Display error message using SweetAlert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting your feedback.",
      });
    }
  };

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  // Custom SVG paths for filled and empty states of stars
  const starPaths = {
    filled:
      "M12 2.69l1.92 5.88h6.22l-5.03 3.66 1.92 5.88-5.03-3.67-5.03 3.67 1.92-5.88-5.03-3.66h6.22z",
    empty:
      "M12 2.69l1.92 5.88h6.22l-5.03 3.66 1.92 5.88-5.03-3.67-5.03 3.67 1.92-5.88-5.03-3.66h6.22z",
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <animated.div
          className="main p-4"
          style={{ ...styles.container, ...fadeIn }}
        >
          <div className="text-center mb-4 mt-4">
            <h2 style={styles.heading}>Suggestion and Feedback</h2>
            <p style={styles.description}>
              We value your opinion! Please provide your suggestions and
              feedback to help us improve.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="feedback">Your Feedback:</label>
              <textarea
                className="form-control"
                id="feedback"
                rows="4"
                placeholder="Enter your feedback here..."
                value={formData.feedback}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-group">
              <label>How satisfied are you?</label>
            </div>
            <Rating
              count={5}
              onChange={handleRatingChange}
              size={50} // Increase the size of stars
              activeColor="#FFD700" // Golden color
              isHalf={false} // Disable half rating
              emptyIcon={<StarIcon path={starPaths.empty} />} // Custom empty icon
              filledIcon={<StarIcon path={starPaths.filled} />} // Custom filled icon
            />
            <div>
              <button type="submit" className="btn btn-primary btn-block mt-2">
                Submit Feedback
              </button>
            </div>
          </form>
        </animated.div>
      </div>
    </div>
  );
}

// Custom star icon component
const StarIcon = ({ path }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="24"
    height="24"
  >
    <path d={path} />
  </svg>
);

const styles = {
  container: {
    flex: "1",
    overflow: "auto",
    padding: "20px",
    borderRadius: "10px",
    background: "#f8f9fa", // Light gray background
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "80%", // Set the width to 80% of the parent container
    margin: "0 auto", // Center the container horizontally
    maxWidth: "600px", // Set a maximum width to maintain readability
  },

  heading: {
    color: "#007BFF",
    marginBottom: "20px",
  },
  description: {
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#555",
  },
};
