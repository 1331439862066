// import React, { useState, useEffect } from "react";
// import AaasNav from "../Pages/AaasNav";
// import Sidebar from "../Pages/Sidebar";
// import { DataGrid } from "@mui/x-data-grid";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { jsPDF } from "jspdf";
// import * as XLSX from "xlsx";

// const ExamResult = () => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [examData, setExamData] = useState([]);

//   useEffect(() => {
//     fetchExamData();
//   }, []); 
//   const fetchExamData = () => {
//     axios
//       .get("https://newaaas.tdtlworld.com/student_result_report/")
//       .then((response) => {
//         console.log("Exam Data:", response.data); 
//         if (response.data && response.data.results) {
//           setExamData(response.data.results);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching exam data:", error);
//       });
//   };

//   const columns = [
//     { field: "id", headerName: "ID", width: 150 },
//     { field: "exam_title", headerName: "Exam Title", width: 200 },
//     { field: "full_name", headerName: "Full Name", width: 200 },
//     { field: "field", headerName: "Field", width: 200 },
//     { field: "degree", headerName: "Degree", width: 200 },
//     { field: "marks", headerName: "Marks", width: 150 },
//     { field: "total_marks", headerName: "Total Marks", width: 150 },
//   ];

//   const handleDownloadPDF = () => {
//     const doc = new jsPDF();
//     doc.text("Student Result Report", 10, 10);
//     doc.autoTable({
//       head: [
//         [
//           "ID",
//           "Exam Title",
//           "Full Name",
//           "Field",
//           "Degree",
//           "Marks",
//           "Total Marks",
//         ],
//       ],
//       body: examData.flatMap((exam) =>
//         exam.results.map((student, index) => [
//           `${exam.exam_title}-${index}`, 
//           exam.exam_title,
//           student.full_name,
//           student.field,
//           student.degree,
//           student.marks,
//           student.total_marks,
//         ])
//       ),
//     });
//     doc.save("student_result_report.pdf");
//   };

//   const handleDownloadExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(
//       examData.flatMap((exam) =>
//         exam.results.map((student, index) => ({
//           ID: `${exam.exam_title}-${index}`,
//           "Exam Title": exam.exam_title,
//           "Full Name": student.full_name,
//           "Field": student.field,
//           "Degree": student.degree,
//           Marks: student.marks,
//           "Total Marks": student.total_marks,
//         }))
//       )
//     );
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "Student Results");
//     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//     const data = new Blob([excelBuffer], { type: "application/octet-stream" });
//     saveAs(data, "student_result_report.xlsx");
//   };

//   const searchInputStyle = {
//     padding: "10px",
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//     fontSize: "14px",
//     marginRight: "10px",
//   };

//   const downloadButtonStyle = {
//     padding: "10px",
//     backgroundColor: "#4d90fe",
//     color: "white",
//     border: "none",
//     borderRadius: "4px",
//     cursor: "pointer",
//     fontSize: "14px",
//   };

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main p-3">
//           <h2>Student Result Report</h2>
//           <div style={{ marginBottom: "20px" }}>
//             {/* <FontAwesomeIcon icon={faSearch} /> */}

//             {/* <input
//               type="text"
//               placeholder="Search by Exam Title or Full Name"
//               style={searchInputStyle}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             /> */}
//           </div>
//           <div style={{ marginBottom: "20px" }}>
//             {/* <button
//               style={{
//                 backgroundColor: "#0e2238",
//                 color: "white",
//                 border: "none",
//                 padding: "15px 32px",
//                 textAlign: "center",
//                 textDecoration: "none",
//                 display: "inline-block",
//                 fontSize: "13px",
//                 margin: "4px 2px",
//                 cursor: "pointer",
//                 borderRadius: "8px",
//               }}
//               onClick={handleDownloadPDF}
//             >
//               <FontAwesomeIcon icon={faDownload} /> Download PDF
//             </button> */}
//             <button
//               style={{
//                 backgroundColor: "#0e2238",
//                 color: "white",
//                 border: "none",
//                 padding: "15px 32px",
//                 textAlign: "center",
//                 textDecoration: "none",
//                 display: "inline-block",
//                 fontSize: "13px",
//                 margin: "4px 2px",
//                 cursor: "pointer",
//                 borderRadius: "8px",
//               }}
//               onClick={handleDownloadExcel}
//             >
//               <FontAwesomeIcon icon={faDownload} /> Download Excel
//             </button>
//           </div>
//           <div style={{ height: 400, width: "100%" }}>
//             <DataGrid
//               rows={examData.flatMap((exam) =>
//                 exam.results.map((student, index) => ({
//                   id: `${exam.exam_title}-${index}`, // Generate unique id
//                   exam_title: exam.exam_title,
//                   full_name: student.full_name,
//                   field: student.field,
//                   degree: student.degree,
//                   marks: student.marks,
//                   total_marks: student.total_marks,
//                 }))
//               )}
//               columns={columns}
//               pageSize={5}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExamResult;

import React, { useState, useEffect } from "react";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

const ExamResult = () => {
  const [examData, setExamData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [examTitles, setExamTitles] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");

  useEffect(() => {
    fetchExamData();
  }, []);

  const fetchExamData = () => {
    axios
      .get("https://newaaas.tdtlworld.com/student_result_report/")
      .then((response) => {
        if (response.data && response.data.results) {
          const flattenedData = response.data.results.flatMap((exam, examIndex) =>
            exam.results.map((student, index) => ({
              id: `${exam.exam_title}-${examIndex}-${index}`, 
              exam_title: exam.exam_title,
              full_name: student.full_name,
              field: student.field,
              degree: student.degree,
              marks: student.marks,
              total_marks: student.total_marks,
            }))
          );
          setExamData(flattenedData);
          setFilteredData(flattenedData);

          const uniqueExamTitles = [...new Set(flattenedData.map((item) => item.exam_title))];
          setExamTitles(uniqueExamTitles);
        }
      })
      .catch((error) => {
        console.error("Error fetching exam data:", error);
      });
  };

  const handleExamSelect = (e) => {
    const selectedTitle = e.target.value;
    setSelectedExam(selectedTitle);
    setFilteredData(
      selectedTitle
        ? examData.filter((row) => row.exam_title === selectedTitle)
        : examData
    );
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Student Result Report", 10, 10);
    doc.autoTable({
      head: [["ID", "Exam Title", "Full Name", "Field", "Degree", "Marks", "Total Marks"]],
      body: filteredData.map((row) => [
        row.id,
        row.exam_title,
        row.full_name,
        row.field,
        row.degree,
        row.marks,
        row.total_marks,
      ]),
    });
    doc.save("student_result_report.pdf");
  };

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Student Results");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "student_result_report.xlsx");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "exam_title", headerName: "Exam Title", width: 200 },
    { field: "full_name", headerName: "Full Name", width: 200 },
    { field: "field", headerName: "Field", width: 200 },
    { field: "degree", headerName: "Degree", width: 200 },
    { field: "marks", headerName: "Marks", width: 150 },
    { field: "total_marks", headerName: "Total Marks", width: 150 },
  ];

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3">
          <h2>Student Result Report</h2>
          <div style={{ marginBottom: "20px" }}>
            <select
              value={selectedExam}
              onChange={handleExamSelect}
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              <option value="">Select Exam Title</option>
              {examTitles.map((title, index) => (
                <option key={index} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <button
              style={{ backgroundColor: "#0e2238", color: "white", border: "none", padding: "15px 32px", fontSize: "13px", margin: "4px 2px", cursor: "pointer", borderRadius: "8px" }}
              onClick={handleDownloadPDF}
            >
              <FontAwesomeIcon icon={faDownload} /> Download PDF
            </button>
            <button
              style={{ backgroundColor: "#0e2238", color: "white", border: "none", padding: "15px 32px", fontSize: "13px", margin: "4px 2px", cursor: "pointer", borderRadius: "8px" }}
              onClick={handleDownloadExcel}
            >
              <FontAwesomeIcon icon={faDownload} /> Download Excel
            </button>
          </div>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredData}
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamResult;
