// import React, { useState, useEffect, useCallback } from "react";
// import { Card, Spinner, Table } from "react-bootstrap";
// import Swal from "sweetalert2";
// import Sidebar from "../Pages/Sidebar";
// import AaasNav from "../Pages/AaasNav";
// import { useLocation } from "react-router";
// import { Link } from "react-router-dom"; // Import Link

// const QExamList = () => {
//   const [selectedFilter, setSelectedFilter] = useState("All");
//   const [questions, setQuestions] = useState([]);
//   const [filterButtons, setFilterButtons] = useState([]);
//   const [selectedQuestions, setSelectedQuestions] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10); // Change this to set items per page

//   const location = useLocation();
//   const examId = location.state?.examId;
//   const [examName, setExamName] = useState(""); // State to store exam name

//   useEffect(() => {
//     fetchFilterButtons();
//     fetchQuestions();
//     const storedExamName = localStorage.getItem("exam_title");
//     if (storedExamName) {
//       setExamName(storedExamName);
//     }
//   }, []);
//   const fetchFilterButtons = useCallback(() => {
//     fetch("https://newaaas.tdtlworld.com/all_sub_category/")
//       .then((response) => response.json())
//       .then((data) => {
//         const subCategories = data.sub_categories.map(
//           (category) => category.sub_category
//         );
//         const initialSelectedQuestions = {};
//         subCategories.forEach((subCategory) => {
//           initialSelectedQuestions[subCategory] = [];
//         });
//         setFilterButtons(["All", ...subCategories]);
//         setSelectedQuestions(initialSelectedQuestions);
//       })
//       .catch((error) => console.error("Error fetching filter buttons:", error));
//   }, []);

//   const fetchQuestions = useCallback(() => {
//     setLoading(true);
//     fetch("https://newaaas.tdtlworld.com/fetch_question_list/", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setQuestions(data.questions || []);
//         setLoading(false);
//       })
//       .catch((error) => console.error("Error fetching questions:", error));
//   }, []);

//   const fetchQuestionsBySubcategory = useCallback((subCategoryId) => {
//     setLoading(true);
//     fetch("https://newaaas.tdtlworld.com/sub_category_description/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ sub_category_Id: subCategoryId }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setQuestions(data.questions || []);
//         setLoading(false);
//       })
//       .catch((error) =>
//         console.error("Error fetching questions by subcategory:", error)
//       );
//   }, []);

//   const handleFilterChange = useCallback(
//     (filter, subCategoryId) => {
//       setSelectedFilter(filter);
//       if (filter !== "All") {
//         fetchQuestionsBySubcategory(subCategoryId);
//       } else {
//         fetchQuestions();
//       }
//     },
//     [fetchQuestions, fetchQuestionsBySubcategory]
//   );

//   const handleCheckboxChange = useCallback((questionId, subCategory) => {
//     setSelectedQuestions((prevSelectedQuestions) => {
//       const updatedSelectedQuestions = { ...prevSelectedQuestions };
//       if (!updatedSelectedQuestions[subCategory]) {
//         updatedSelectedQuestions[subCategory] = [];
//       }
//       const isSelected =
//         updatedSelectedQuestions[subCategory].includes(questionId);
//       if (isSelected) {
//         updatedSelectedQuestions[subCategory] = updatedSelectedQuestions[
//           subCategory
//         ].filter((id) => id !== questionId);
//       } else {
//         updatedSelectedQuestions[subCategory].push(questionId);
//       }
//       return updatedSelectedQuestions;
//     });
//   }, []);

//   const handleSubmit = () => {
//     console.log("Selected questions:", selectedQuestions);
//     console.log("Exam ID:", examId);

//     const payload = {
//       id: examId,
//       questions_id: Object.values(selectedQuestions).flat(),
//     };

//     fetch("https://newaaas.tdtlworld.com/exam_question_set/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Response from server:", data);
//         if (data.message === "Questions added to the exam successfully.") {
//           Swal.fire({
//             icon: "success",
//             title: "Success",
//             text: "Selected questions submitted successfully!",
//           });
//         } else {
//           console.error("Failed to add questions to the exam.");
//         }
//       })
//       .catch((error) => console.error("Error submitting data:", error));
//   };

//   // Calculate current items based on pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = questions.slice(indexOfFirstItem, indexOfLastItem);

//   // Change page
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div
//           className="main p-3"
//           style={{
//             flex: "1",
//             overflow: "auto",
//             // background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
//           }}
//         >
//           <div className="container mt-5">
//             <h2 className="mb-4">Select Questions for {examName} exam </h2>{" "}
//             {/* Display examName here */}
//             <div className="filter-buttons mb-4">
//               {filterButtons.map((button, index) => (
//                 <button
//                   key={index}
//                   className={`btn ${
//                     selectedFilter === button ? "btn-dark" : "btn-light"
//                   }`}
//                   style={{ marginRight: "10px" }}
//                   onClick={() => handleFilterChange(button, index)}
//                 >
//                   {button}
//                 </button>
//               ))}
//             </div>
//             {/* Table for displaying questions */}
//             <div style={{ maxHeight: "400px", overflowY: "auto" }}>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>Select</th>
//                     <th>Description</th>
//                     <th>Marks</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {loading ? (
//                     <tr>
//                       <td colSpan="3">
//                         <Spinner animation="border" role="status">
//                           <span className="visually-hidden">Loading...</span>
//                         </Spinner>
//                       </td>
//                     </tr>
//                   ) : currentItems.length > 0 ? (
//                     currentItems.map((question) => (
//                       <tr key={question.questions_id}>
//                         <td>
//                           <input
//                             type="checkbox"
//                             className="form-check-input"
//                             checked={selectedQuestions[
//                               selectedFilter
//                             ]?.includes(question.questions_id)}
//                             onChange={() =>
//                               handleCheckboxChange(
//                                 question.questions_id,
//                                 selectedFilter
//                               )
//                             }
//                           />
//                         </td>
//                         <td>{question.description}</td>
//                         <td>{question.marks}</td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="3">No questions found.</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </Table>
//             </div>
//             {/* Pagination */}
//             <ul className="pagination">
//               {Array.from({
//                 length: Math.ceil(questions.length / itemsPerPage),
//               }).map((_, index) => (
//                 <li key={index} className="page-item">
//                   <button
//                     onClick={() => paginate(index + 1)}
//                     className="page-link"
//                   >
//                     {index + 1}
//                   </button>
//                 </li>
//               ))}
//             </ul>
//             <div className="selected-questions">

//               <button
//                 className="btn btn-primary"
//                 onClick={handleSubmit}
//                 style={{
//                   backgroundColor: "#0e2238",
//                   color: "white",
//                   border: "none",
//                   padding: "15px 32px",
//                   textAlign: "center",
//                   textDecoration: "none",
//                   display: "inline-block",
//                   fontSize: "13px",
//                   margin: "4px 2px",
//                   cursor: "pointer",
//                   borderRadius: "8px",
//                 }}
//               >
//                 Submit Selected Questions
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QExamList;
// import React, { useState, useEffect, useCallback } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import Swal from "sweetalert2";
// import Sidebar from "../Pages/Sidebar";
// import AaasNav from "../Pages/AaasNav";
// import { useLocation } from "react-router";
// import { Link } from "react-router-dom";

// const QExamList = () => {
//   const [selectedFilter, setSelectedFilter] = useState("All");
//   const [questions, setQuestions] = useState([]);
//   const [filterButtons, setFilterButtons] = useState([]);
//   const [selectedQuestions, setSelectedQuestions] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [examName, setExamName] = useState("");
//   const [currentPage, setCurrentPage] = useState(0); // Added currentPage state
//   const [itemsPerPage] = useState(10); // Defined itemsPerPage

//   const location = useLocation();
//   const examId = location.state?.examId;

//   useEffect(() => {
//     fetchFilterButtons();
//     fetchQuestions();
//     const storedExamName = localStorage.getItem("exam_title");
//     if (storedExamName) {
//       setExamName(storedExamName);
//     }
//   }, []);

//   const fetchFilterButtons = useCallback(() => {
//     fetch("https://newaaas.tdtlworld.com/all_sub_category/")
//       .then((response) => response.json())
//       .then((data) => {
//         const subCategories = data.sub_categories.map(
//           (category) => category.sub_category
//         );
//         const initialSelectedQuestions = {};
//         subCategories.forEach((subCategory) => {
//           initialSelectedQuestions[subCategory] = [];
//         });
//         setFilterButtons(["All", ...subCategories]);
//         setSelectedQuestions(initialSelectedQuestions);
//       })
//       .catch((error) => console.error("Error fetching filter buttons:", error));
//   }, []);

//   const fetchQuestions = useCallback(() => {
//     setLoading(true);
//     fetch("https://newaaas.tdtlworld.com/fetch_question_list/", {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setQuestions(data.questions || []);
//         setLoading(false);
//       })
//       .catch((error) => console.error("Error fetching questions:", error));
//   }, []);

//   const fetchQuestionsBySubcategory = useCallback((subCategoryId) => {
//     setLoading(true);
//     fetch("https://newaaas.tdtlworld.com/sub_category_description/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ sub_category_Id: subCategoryId }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setQuestions(data.questions || []);
//         setLoading(false);
//       })
//       .catch((error) =>
//         console.error("Error fetching questions by subcategory:", error)
//       );
//   }, []);

//   const handleFilterChange = useCallback(
//     (filter, subCategoryId) => {
//       setSelectedFilter(filter);
//       if (filter !== "All") {
//         fetchQuestionsBySubcategory(subCategoryId);
//       } else {
//         fetchQuestions();
//       }
//     },
//     [fetchQuestions, fetchQuestionsBySubcategory]
//   );

//   const handleCheckboxChange = useCallback((questionId, subCategory) => {
//     setSelectedQuestions((prevSelectedQuestions) => {
//       const updatedSelectedQuestions = { ...prevSelectedQuestions };
//       if (!updatedSelectedQuestions[subCategory]) {
//         updatedSelectedQuestions[subCategory] = [];
//       }
//       const isSelected =
//         updatedSelectedQuestions[subCategory].includes(questionId);
//       if (isSelected) {
//         updatedSelectedQuestions[subCategory] = updatedSelectedQuestions[
//           subCategory
//         ].filter((id) => id !== questionId);
//       } else {
//         updatedSelectedQuestions[subCategory].push(questionId);
//       }
//       return updatedSelectedQuestions;
//     });
//   }, []);

//   const handleSubmit = () => {
//     console.log("Selected questions:", selectedQuestions);
//     console.log("Exam ID:", examId);

//     const payload = {
//       id: examId,
//       questions_id: Object.values(selectedQuestions).flat(),
//     };

//     fetch("https://newaaas.tdtlworld.com/exam_question_set/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Response from server:", data);
//         if (data.message === "Questions added to the exam successfully.") {
//           Swal.fire({
//             icon: "success",
//             title: "Success",
//             text: "Selected questions submitted successfully!",
//           });
//         } else {
//           console.error("Failed to add questions to the exam.");
//         }
//       })
//       .catch((error) => console.error("Error submitting data:", error));
//   };

//   const columns = [
//     {
//       field: "select",
//       headerName: "Select",
//       width: 100,
//       renderCell: (params) => (
//         <input
//           type="checkbox"
//           className="form-check-input"
//           checked={selectedQuestions[selectedFilter]?.includes(
//             params.row.questions_id
//           )}
//           onChange={() =>
//             handleCheckboxChange(params.row.questions_id, selectedFilter)
//           }
//         />
//       ),
//     },
//     { field: "description", headerName: "Description", width: 500 },
//     { field: "marks", headerName: "Marks", width: 150 },
//   ];

//   const rows = questions.map((question) => ({
//     id: question.questions_id || question.description, // Ensure each row has a unique id
//     questions_id: question.questions_id,
//     description: question.description,
//     marks: question.marks,
//   }));

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
//           <div className="container mt-5">
//             <h2 className="mb-4">Select Questions for {examName} exam</h2>
//             <div className="filter-buttons mb-4">
//               {filterButtons.map((button, index) => (
//                 <button
//                   key={index}
//                   className={`btn ${
//                     selectedFilter === button ? "btn-dark" : "btn-light"
//                   }`}
//                   style={{ marginRight: "10px" }}
//                   onClick={() => handleFilterChange(button, index)}
//                 >
//                   {button}
//                 </button>
//               ))}
//             </div>
//             <div style={{ height: 400, width: "100%" }}>
//               <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 pageSize={itemsPerPage}
//                 loading={loading}
//                 pagination
//                 rowsPerPageOptions={[itemsPerPage]}
//                 onPageChange={(params) => setCurrentPage(params.page)}
//                 getRowId={(row) => row.id} // Specify the custom id for each row
//               />
//             </div>
//             <button
//               className="btn btn-primary mt-3"
//               onClick={handleSubmit}
//               style={{
//                 backgroundColor: "#0e2238",
//                 color: "white",
//                 border: "none",
//                 padding: "15px 32px",
//                 textAlign: "center",
//                 textDecoration: "none",
//                 display: "inline-block",
//                 fontSize: "13px",
//                 margin: "4px 2px",
//                 cursor: "pointer",
//                 borderRadius: "8px",
//               }}
//             >
//               Submit Selected Questions
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default QExamList;
import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import Sidebar from "../Pages/Sidebar";
import AaasNav from "../Pages/AaasNav";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { Spinner } from "react-bootstrap";

const QExamList = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [questions, setQuestions] = useState([]);
  const [filterButtons, setFilterButtons] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false); // State for submission loading
  const [examName, setExamName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate function
  const examId = location.state?.examId;

  useEffect(() => {
    fetchFilterButtons();
    fetchQuestions();
    const storedExamName = localStorage.getItem("exam_title");
    if (storedExamName) {
      setExamName(storedExamName);
    }
  }, []);

  const fetchFilterButtons = useCallback(() => {
    fetch("https://newaaas.tdtlworld.com/all_sub_category/")
      .then((response) => response.json())
      .then((data) => {
        const subCategories = data.sub_categories.map(
          (category) => category.sub_category
        );
        const initialSelectedQuestions = {};
        subCategories.forEach((subCategory) => {
          initialSelectedQuestions[subCategory] = [];
        });
        setFilterButtons(["All", ...subCategories]);
        setSelectedQuestions(initialSelectedQuestions);
      })
      .catch((error) => console.error("Error fetching filter buttons:", error));
  }, []);

  const fetchQuestions = useCallback(() => {
    setLoading(true);
    fetch("https://newaaas.tdtlworld.com/fetch_question_list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.questions || []);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  }, []);

  const fetchQuestionsBySubcategory = useCallback((subCategoryId) => {
    setLoading(true);
    fetch("https://newaaas.tdtlworld.com/sub_category_description/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sub_category_Id: subCategoryId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.questions || []);
        setLoading(false);
      })
      .catch((error) =>
        console.error("Error fetching questions by subcategory:", error)
      );
  }, []);

  const handleFilterChange = useCallback(
    (filter, subCategoryId) => {
      setSelectedFilter(filter);
      if (filter !== "All") {
        fetchQuestionsBySubcategory(subCategoryId);
      } else {
        fetchQuestions();
      }
    },
    [fetchQuestions, fetchQuestionsBySubcategory]
  );

  const handleCheckboxChange = useCallback((questionId, subCategory) => {
    setSelectedQuestions((prevSelectedQuestions) => {
      const updatedSelectedQuestions = { ...prevSelectedQuestions };
      if (!updatedSelectedQuestions[subCategory]) {
        updatedSelectedQuestions[subCategory] = [];
      }
      const isSelected =
        updatedSelectedQuestions[subCategory].includes(questionId);
      if (isSelected) {
        updatedSelectedQuestions[subCategory] = updatedSelectedQuestions[
          subCategory
        ].filter((id) => id !== questionId);
      } else {
        updatedSelectedQuestions[subCategory].push(questionId);
      }
      return updatedSelectedQuestions;
    });
  }, []);

  const handleSubmit = () => {
    setLoadingSubmit(true); // Set loadingSubmit to true when starting the submission
    console.log("Selected questions:", selectedQuestions);
    console.log("Exam ID:", examId);

    const payload = {
      id: examId,
      questions_id: Object.values(selectedQuestions).flat(),
    };

    fetch("https://newaaas.tdtlworld.com/exam_question_set/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from server:", data);
        setLoadingSubmit(false); // Set loadingSubmit to false when submission is complete
        if (data.message === "Questions added to the exam successfully.") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Selected questions submitted successfully!",
          }).then(() => {
            navigate("/allExamLst"); // Navigate to /allExamLst after successful submission
          });
        } else {
          console.error("Failed to add questions to the exam.");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setLoadingSubmit(false); // Set loadingSubmit to false in case of error
      });
  };

  const columns = [
    {
      field: "select",
      headerName: "Select",
      width: 100,
      renderCell: (params) => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selectedQuestions[selectedFilter]?.includes(
            params.row.questions_id
          )}
          onChange={() =>
            handleCheckboxChange(params.row.questions_id, selectedFilter)
          }
        />
      ),
    },
    { field: "description", headerName: "Description", width: 500 },
    { field: "marks", headerName: "Marks", width: 150 },
  ];

  const rows = questions.map((question) => ({
    id: question.questions_id || question.description,
    questions_id: question.questions_id,
    description: question.description,
    marks: question.marks,
  }));

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <div className="container mt-5">
            <h2 className="mb-4">Select Questions for {examName} exam</h2>
            <div className="filter-buttons mb-4">
              {filterButtons.map((button, index) => (
                <button
                  key={index}
                  className={`btn ${
                    selectedFilter === button ? "btn-dark" : "btn-light"
                  }`}
                  style={{ marginRight: "10px" }}
                  onClick={() => handleFilterChange(button, index)}
                >
                  {button}
                </button>
              ))}
            </div>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                loading={loading}
                pagination
                rowsPerPageOptions={[itemsPerPage]}
                onPageChange={(params) => setCurrentPage(params.page)}
                getRowId={(row) => row.id}
              />
            </div>
            <div className="d-flex justify-content-center mt-3">
              {loadingSubmit ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Submitting...</span>
                </Spinner>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#0e2238",
                    color: "white",
                    border: "none",
                    padding: "15px 32px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "13px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                >
                  Submit Selected Questions
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QExamList;
