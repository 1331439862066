// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import AaasNav from "../Pages/AaasNav";
// import Sidebar from "../Pages/Sidebar";
// import Swal from "sweetalert2";
// import Select from "react-select";
// import { useNavigate } from "react-router-dom";
// import { Button, Modal, Form, Spinner } from "react-bootstrap";
// import { DataGrid } from "@mui/x-data-grid";
// import Dropzone from "react-dropzone";

// export default function AddExam() {
//   const [examData, setExamData] = useState({
//     examName: "",
//     date: new Date().toISOString().split("T")[0],
//     time: "",
//     ampm: "AM",
//     marks: "",
//     description: "",
//     rules: "",
//     duration: "",
//     selectedStudents: [],
//     creator: "",
//     file: null,
//   });
//   const [users, setUsers] = useState([]);
//   const [exams, setExams] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [uploadedExcel, setUploadedExcel] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userId = localStorage.getItem("user_id");
//     const full_name = localStorage.getItem("full_name");

//     if (full_name) {
//       setExamData((prevState) => ({
//         ...prevState,
//         creator: full_name,
//       }));
//     }

//     axios
//       .get("https://newaaas.tdtlworld.com/fetch_users_by_role/")
//       .then((response) => {
//         setUsers(response.data.users);
//       })
//       .catch((error) => {
//         console.error("Error fetching users:", error);
//       });

//     axios
//       .get("https://newaaas.tdtlworld.com/fetch_all_exams/", {
//         params: {
//           user_id: userId,
//         },
//       })
//       .then((response) => {
//         const updatedExams = response.data.exams
//           .map((exam) => ({
//             id: exam.id,
//             ...exam,
//           }))
//           .filter((exam) => exam.creator === full_name);
//         setExams(updatedExams);
//       })
//       .catch((error) => {
//         console.error("Error fetching exams:", error);
//       });
//   }, []);

//   const handleFileDrop = (acceptedFiles) => {
//     const file = acceptedFiles[0];
//     setExamData({
//       ...examData,
//       file,
//     });

//     const reader = new FileReader();
//     reader.onload = (e) => {
//       setUploadedExcel(e.target.result);
//     };
//     reader.readAsText(file);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setExamData({
//       ...examData,
//       [name]: value,
//     });
//   };

//   const handleSelectChange = (selectedOption) => {
//     const selectedStudents = selectedOption.map((option) => option.value);
//     setExamData({
//       ...examData,
//       selectedStudents,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const userId = localStorage.getItem("user_id");
//       localStorage.setItem("exam_title", examData.examName);

//       const formData = new FormData();
//       formData.append("title", examData.examName);
//       formData.append("start_date", examData.date);
//       formData.append("description", examData.description);
//       formData.append("time", `${examData.time} ${examData.ampm}`);
//       formData.append("duration", examData.duration);
//       formData.append("marks", examData.marks);
//       formData.append("rules", examData.rules);
//       formData.append("select_students", examData.selectedStudents);
//       formData.append("creator", examData.creator);
//       formData.append("file", examData.file);

//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/exam/",
//         formData
//       );

//       Swal.fire({
//         icon: "success",
//         title: "Exam Added Successfully",
//         showConfirmButton: false,
//         timer: 1000,
//       });

//       setExamData({
//         examName: "",
//         date: new Date().toISOString().split("T")[0],
//         time: "",
//         ampm: "AM",
//         marks: "",
//         description: "",
//         rules: "",
//         duration: "",
//         selectedStudents: [],
//         creator: "",
//         file: null,
//       });

//       const updatedExamsResponse = await axios.get(
//         "https://newaaas.tdtlworld.com/fetch_all_exams/",
//         {
//           params: {
//             user_id: userId,
//           },
//         }
//       );
//       const updatedExamsData = updatedExamsResponse.data.exams;
//       if (updatedExamsData.length > 0) {
//         let latestId = updatedExamsData[updatedExamsData.length - 1].id;
//         const examIdState = { examId: latestId };
//         navigate("/QuestonBanLst", {
//           state: examIdState,
//         });
//         setExams(updatedExamsData);
//       } else {
//         console.log("No exams found in the response.");
//       }

//       handleCloseModal();
//     } catch (error) {
//       console.error("Error adding exam:", error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleShowModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);
//   const dropzoneStyle = {
//     border: "2px dashed #cccccc",
//     borderRadius: "4px",
//     padding: "20px",
//     textAlign: "center",
//     cursor: "pointer",
//   };
//   const columns = [
//     { field: "title", headerName: "Exam Name", width: 200 },
//     { field: "start_date", headerName: "Date", width: 150 },
//     { field: "time", headerName: "Time", width: 150 },
//     { field: "total_marks", headerName: "Marks", width: 120 },
//     { field: "description", headerName: "Description", width: 200 },
//     { field: "rules", headerName: "Rules", width: 200 },
//     { field: "duration", headerName: "Duration", width: 150 },
//     { field: "creator", headerName: "Creator", width: 150 },
//   ];
//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main p-3">
//           <div className="text-start mb-1">
//             <Button
//               variant="primary"
//               onClick={handleShowModal}
//               style={{
//                 backgroundColor: "#0e2238",
//                 color: "white",
//                 border: "none",
//                 padding: "15px 32px",
//                 textAlign: "center",
//                 textDecoration: "none",
//                 display: "inline-block",
//                 fontSize: "13px",
//                 margin: "4px 2px",
//                 cursor: "pointer",
//                 borderRadius: "8px",
//               }}
//             >
//               + Add Exam
//             </Button>
//           </div>

//           <Modal show={showModal} onHide={handleCloseModal}>
//             <Modal.Header closeButton>
//               <Modal.Title>Add Exam</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <Form onSubmit={handleSubmit}>
//                 <Form.Group className="mb-3" controlId="examName">
//                   <Form.Label>Exam Name:</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="examName"
//                     value={examData.examName}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="date">
//                   <Form.Label>Date:</Form.Label>
//                   <Form.Control
//                     type="date"
//                     name="date"
//                     value={examData.date}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="time">
//                   <Form.Label>Time:</Form.Label>
//                   <div className="d-flex">
//                     <Form.Control
//                       type="time"
//                       name="time"
//                       value={examData.time}
//                       onChange={handleInputChange}
//                     />
//                     <Form.Select
//                       className="ms-2"
//                       name="ampm"
//                       value={examData.ampm}
//                       onChange={handleInputChange}
//                     >
//                       <option value="AM">AM</option>
//                       <option value="PM">PM</option>
//                     </Form.Select>
//                   </div>
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="marks">
//                   <Form.Label>Marks:</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="marks"
//                     value={examData.marks}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="description">
//                   <Form.Label>Description:</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     name="description"
//                     value={examData.description}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="rules">
//                   <Form.Label>Rules:</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     name="rules"
//                     value={examData.rules}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="duration">
//                   <Form.Label>Duration (in minutes):</Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="duration"
//                     value={examData.duration}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="selectedStudents">
//                   <Form.Label>Select Students:</Form.Label>
//                   <Select
//                     isMulti
//                     name="selectedStudents"
//                     options={users.map((user) => ({
//                       value: user.user_id,
//                       label: `${user.full_name} (${user.email})`,
//                     }))}
//                     onChange={handleSelectChange}
//                   />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="excelFile">
//                   <Form.Label>Upload Excel Sheet:</Form.Label>
//                   <Dropzone onDrop={handleFileDrop}>
//                     {({ getRootProps, getInputProps }) => (
//                       <div {...getRootProps()} style={dropzoneStyle}>
//                         <input {...getInputProps()} />
//                         <p>
//                           {examData.file ? (
//                             <span>{examData.file.name}</span>
//                           ) : (
//                             "Drag 'n' drop Excel file here, or click to select file"
//                           )}
//                         </p>
//                       </div>
//                     )}
//                   </Dropzone>
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="creator">
//                   <Form.Label>Creator:</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="creator"
//                     value={examData.creator}
//                     readOnly
//                   />
//                 </Form.Group>
//                 <Button
//                   variant="success"
//                   type="submit"
//                   style={{ marginRight: "10px" }}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <>
//                       <Spinner
//                         as="span"
//                         animation="border"
//                         size="sm"
//                         role="status"
//                         aria-hidden="true"
//                       />
//                       {" Adding..."}
//                     </>
//                   ) : (
//                     "+ Add exam"
//                   )}
//                 </Button>
//                 <Button variant="secondary" onClick={handleCloseModal}>
//                   Close
//                 </Button>
//               </Form>
//             </Modal.Body>
//           </Modal>

//           <h1 className="text-center mt-3"></h1>
//           <div style={{ height: 400, width: "100%", backgroundColor: "white" }}>
//             <DataGrid rows={exams} columns={columns} pageSize={5} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import axios from "axios";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";
import Swal from "sweetalert2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import Dropzone from "react-dropzone";
import { Vortex } from "react-loader-spinner";

export default function AddExam() {
  const [examData, setExamData] = useState({
    examName: "",
    date: new Date().toISOString().split("T")[0],
    time: "",
    ampm: "AM",
    marks: "",
    description: "",
    rules: "",
    duration: "",
    selectedStudents: [],
    creator: "",
    file: null,
  });
  const [users, setUsers] = useState([]);
  const [exams, setExams] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadedExcel, setUploadedExcel] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const full_name = localStorage.getItem("full_name");

    if (full_name) {
      setExamData((prevState) => ({
        ...prevState,
        creator: full_name,
      }));
    }

    axios
      .get("https://newaaas.tdtlworld.com/fetch_users_by_role/")
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    axios
      .get("https://newaaas.tdtlworld.com/fetch_all_exams/", {
        params: {
          user_id: userId,
        },
      })
      .then((response) => {
        const updatedExams = response.data.exams
          .map((exam) => ({
            id: exam.id,
            ...exam,
          }))
          .filter((exam) => exam.creator === full_name);
        setExams(updatedExams);
      })
      .catch((error) => {
        console.error("Error fetching exams:", error);
      });
  }, []);

  const handleFileDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setExamData({
      ...examData,
      file,
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedExcel(e.target.result);
    };
    reader.readAsText(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExamData({
      ...examData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    const selectedStudents = selectedOption.map((option) => option.value);
    setExamData({
      ...examData,
      selectedStudents,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userId = localStorage.getItem("user_id");
      localStorage.setItem("exam_title", examData.examName);

      const formData = new FormData();
      formData.append("title", examData.examName);
      formData.append("start_date", examData.date);
      formData.append("description", examData.description);
      formData.append("time", `${examData.time} ${examData.ampm}`);
      formData.append("duration", examData.duration);
      formData.append("marks", examData.marks);
      formData.append("rules", examData.rules);
      formData.append("select_students", examData.selectedStudents);
      formData.append("creator", examData.creator);
      formData.append("file", examData.file);

      const response = await axios.post(
        "https://newaaas.tdtlworld.com/exam/",
        formData
      );

      Swal.fire({
        icon: "success",
        title: "Exam Added Successfully",
        showConfirmButton: false,
        timer: 1000,
      });

      setExamData({
        examName: "",
        date: new Date().toISOString().split("T")[0],
        time: "",
        ampm: "AM",
        marks: "",
        description: "",
        rules: "",
        duration: "",
        selectedStudents: [],
        creator: "",
        file: null,
      });

      const updatedExamsResponse = await axios.get(
        "https://newaaas.tdtlworld.com/fetch_all_exams/",
        {
          params: {
            user_id: userId,
          },
        }
      );
      const updatedExamsData = updatedExamsResponse.data.exams;
      if (updatedExamsData.length > 0) {
        let latestId = updatedExamsData[updatedExamsData.length - 1].id;
        const examIdState = { examId: latestId };
        navigate("/QuestonBanLst", {
          state: examIdState,
        });
        setExams(updatedExamsData);
      } else {
        console.log("No exams found in the response.");
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error adding exam:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const dropzoneStyle = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  const columns = [
    { field: "title", headerName: "Exam Name", width: 200 },
    { field: "start_date", headerName: "Date", width: 150 },
    { field: "time", headerName: "Time", width: 150 },
    { field: "total_marks", headerName: "Marks", width: 120 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "rules", headerName: "Rules", width: 200 },
    { field: "duration", headerName: "Duration", width: 150 },
    { field: "creator", headerName: "Creator", width: 150 },
  ];
  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3">
          <div className="text-start mb-1">
            <Button
              variant="primary"
              onClick={handleShowModal}
              style={{
                backgroundColor: "#0e2238",
                color: "white",
                border: "none",
                padding: "15px 32px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "13px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "8px",
              }}
            >
              + Add Exam
            </Button>
          </div>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Exam</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="examName">
                  <Form.Label>Exam Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="examName"
                    value={examData.examName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="date">
                  <Form.Label>Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={examData.date}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>Time:</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      type="time"
                      name="time"
                      value={examData.time}
                      onChange={handleInputChange}
                    />
                    <Form.Select
                      className="ms-2"
                      name="ampm"
                      value={examData.ampm}
                      onChange={handleInputChange}
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </Form.Select>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="marks">
                  <Form.Label>Marks:</Form.Label>
                  <Form.Control
                    type="text"
                    name="marks"
                    value={examData.marks}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={examData.description}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="rules">
                  <Form.Label>Rules:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="rules"
                    value={examData.rules}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="duration">
                  <Form.Label>Duration (in minutes):</Form.Label>
                  <Form.Control
                    type="number"
                    name="duration"
                    value={examData.duration}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="selectedStudents">
                  <Form.Label>Select Students:</Form.Label>
                  <Select
                    isMulti
                    name="selectedStudents"
                    options={users.map((user) => ({
                      value: user.user_id,
                      label: `${user.full_name} (${user.email})`,
                    }))}
                    onChange={handleSelectChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="excelFile">
                  <Form.Label>Upload Excel Sheet:</Form.Label>
                  <Dropzone onDrop={handleFileDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} style={dropzoneStyle}>
                        <input {...getInputProps()} />
                        <p>
                          {examData.file ? (
                            <span>{examData.file.name}</span>
                          ) : (
                            "Drag 'n' drop Excel file here, or click to select file"
                          )}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </Form.Group>
                <Form.Group className="mb-3" controlId="creator">
                  <Form.Label>Creator:</Form.Label>
                  <Form.Control
                    type="text"
                    name="creator"
                    value={examData.creator}
                    readOnly
                  />
                </Form.Group>
                <Button
                  variant="success"
                  type="submit"
                  style={{ marginRight: "10px" }}
                  disabled={loading}
                >
                  {loading ? (
                    <Vortex
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass="vortex-wrapper"
                      colors={[
                        "red",
                        "green",
                        "blue",
                        "yellow",
                        "orange",
                        "purple",
                      ]}
                    />
                  ) : (
                    "+ Add exam"
                  )}
                </Button>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <h1 className="text-center mt-3"></h1>
          <div style={{ height: 400, width: "100%", backgroundColor: "white" }}>
            <DataGrid rows={exams} columns={columns} pageSize={5} />
          </div>
        </div>
      </div>
    </div>
  );
}
