import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faEye,
  faEyeSlash,
  faCheckCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import bregster from "../Pages/bregster.png";
import Webcam from "react-webcam";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Vortex } from "react-loader-spinner";

const RegistrationForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [cameraOpen, setCameraOpen] = useState(true);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Student");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [field, setfield] = useState("");
  const [degree, setDegree] = useState(""); // New state for degree
  const [faceDetectionLoading, setFaceDetectionLoading] = useState(false);

  const [instructorReferenceSent, setInstructorReferenceSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const webcamRef = useRef(null);

  const handleRegister = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    setErrors({});

    let registrationData = {
      name,
      email,
      password,
      profile_photo: profilePhoto,
      role: selectedTab.toLowerCase(),
    };

    if (selectedTab === "Student") {
      registrationData.instructor_reference = referenceNumber;
      registrationData.field = field;
      registrationData.degree = degree;
    }

    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/registration/",
        registrationData
      );

      if (response.data.success) {
        setRegistrationSuccess(true);
        clearForm();
        if (selectedTab === "Instructor") {
          await sendInstructorReferenceCode();
        }
      } else {
        Swal.fire("Error", response.data.error, "error");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire("Error", "An error occurred during registration.", "error");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = "Full Name is required.";
    if (!email) errors.email = "Email is required.";
    if (!password) errors.password = "Password is required.";
    if (!confirmPassword)
      errors.confirmPassword = "Confirm Password is required.";
    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords do not match.";
    if (selectedTab === "Student") {
      if (!referenceNumber) {
        errors.referenceNumber = "Reference Number is required.";
      }
      if (!profilePhoto) {
        Swal.fire(
          "Error",
          "Profile photo and all fields are required for Student registration.",
          "error"
        );
        errors.profilePhoto = "Profile photo is required.";
      }
      if (!field) errors.field = "field is required.";
      if (!degree) errors.degree = "Degree is required."; // Validate degree
    }
    return errors;
  };
  const toggleCamera = () => {
    setCameraOpen(!cameraOpen);
  };
  const handleDegreeChange = (e) => {
    setDegree(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setErrors({});
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setProfilePhoto(imageSrc);
    await detectFace(imageSrc);
  };
  const handlefieldChange = (e) => {
    setfield(e.target.value);
  };
  const handleReferenceNumberChange = (e) => {
    setReferenceNumber(e.target.value);
  };

  const sendInstructorReferenceCode = async () => {
    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/send_reference_code/",
        { email }
      );

      if (response.data.message) {
        setInstructorReferenceSent(true);
        Swal.fire({
          icon: "success",
          title: "Reference Code Sent",
          text: "The reference number has been sent to your email. Please check.",
        });
      } else {
        Swal.fire("Error", response.data.error, "error");
      }
    } catch (error) {
      console.error("Error sending reference code:", error);
      Swal.fire(
        "Error",
        "An error occurred while sending the reference code.",
        "error"
      );
    }
  };
  const detectFace = async (imageSrc) => {
    setFaceDetectionLoading(true);
    try {
      const response = await axios.post(
        "https://newaaas.tdtlworld.com/check_faces/",
        { image: imageSrc }
      );

      const { result, number_of_faces } = response.data;

      if (result) {
        Swal.fire({
          icon: "success",
          title: "Face Captured",
          // text: `${number_of_faces} face(s) detected.`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Please Recapture",
          text: "Please try again Recapture.",
        });
        setProfilePhoto("");
      }
    } catch (error) {
      console.error("Error detecting face:", error);
      Swal.fire("Error", "Clear Face Required.", "error");
    } finally {
      setFaceDetectionLoading(false);
    }
  };
  // const detectFace = async (imageSrc) => {
  //   setFaceDetectionLoading(true);
  //   try {
  //     const encodedImage = encodeURIComponent(imageSrc);
  //     const response = await axios.get(
  //       "https://newaaas.tdtlworld.com/check_faces/",
  //       { image: imageSrc }
  //     );

  //     const { result, number_of_faces } = response.data;

  //     if (result) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Face Detected",
  //         text: `${number_of_faces} face(s) detected.`,
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "No Face Detected",
  //         text: "Please try again.",
  //       });
  //       setProfilePhoto("");
  //     }
  //   } catch (error) {
  //     console.error("Error detecting face:", error);
  //     Swal.fire("Error", "Clear Face Required Please Recapture.", "error");
  //   } finally {
  //     setFaceDetectionLoading(false);
  //   }
  // };

  // const detectFace = async (imageSrc) => {
  //   setFaceDetectionLoading(true);
  //   try {
  //     const response = await axios.post(
  //       "https://newaaas.tdtlworld.com/check_faces/",
  //       { image: imageSrc }
  //     );

  //     const { result, number_of_faces } = response.data;

  //     if (result) {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Face Detected",
  //         text: `${number_of_faces} face(s) detected.`,
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "No Face Detected",
  //         text: "Please try again.",
  //       });
  //       setProfilePhoto("");
  //     }
  //   } catch (error) {
  //     console.error("Error detecting face:", error);
  //     Swal.fire("Error", "Clear Face Required.", "error");
  //   } finally {
  //     setFaceDetectionLoading(false);
  //   }
  // };

  const renderWebcamSection = () => {
    if (selectedTab === "Student") {
      return (
        <>
          {cameraOpen ? (
            <div className="position-relative">
              <Webcam
                audio={false}
                // height={200}
                // width={280}
                style={{
                  height: "200px",
                  width: "280px",
                }}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "280px",
                  height: "200px",
                  zIndex: "99",
                  backgroundImage:
                    "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
                  backgroundSize: "cover",
                  opacity: "0.4",
                }}
              ></div>
              <button
                type="button"
                className="btn btn-outline-secondary mt-2"
                onClick={capture}
              >
                <FontAwesomeIcon icon={faCamera} /> Capture
              </button>{" "}
            </div>
          ) : (
            <div className="text-muted">Camera is closedd.</div>
          )}
          <button
            type="button"
            className="btn btn-outline-secondary mt-2"
            onClick={toggleCamera}
          >
            <FontAwesomeIcon icon={cameraOpen ? faEyeSlash : faEye} />{" "}
            {cameraOpen ? "Close Camera" : "Open Camera"}
          </button>
        </>
      );
    } else {
      return null;
    }
  };
  const renderProfilePhotoLabel = () => {
    if (selectedTab === "Student") {
      return (
        <>
          <div className="mb-3">
            <label htmlFor="referenceNumber" className="form-label">
              Reference Number&nbsp;<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="number"
              className={`form-control ${
                errors.referenceNumber ? "is-invalid" : ""
              }`}
              id="referenceNumber"
              value={referenceNumber}
              onChange={handleReferenceNumberChange}
              required
            />
            {errors.referenceNumber && (
              <div className="invalid-feedback">{errors.referenceNumber}</div>
            )}
          </div>
          {/* <div className="mb-3">
            <label htmlFor="field" className="form-label">
              specialization
            </label>
            <input
              type="text"
              placeholder="Enter Specialization "
              className={`form-control ${errors.field ? "is-invalid" : ""}`}
              id="field"
              value={field}
              onChange={handlefieldChange}
              required
            />
            {errors.field && (
              <div className="invalid-feedback">{errors.field}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="degree" className="form-label">
              Degree
            </label>
            <select
              className={`form-control ${errors.degree ? "is-invalid" : ""}`}
              id="degree"
              value={degree}
              onChange={handleDegreeChange}
              required
            >
              <option value="">Select Degree</option>
              <option value="B.Tech">B.Tech</option>
              <option value="B.Com">B.Com</option>
            </select>
            {errors.degree && (
              <div className="invalid-feedback">{errors.degree}</div>
            )}
          </div> */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="field" className="form-label">
                Specialization&nbsp;<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Specialization"
                className={`form-control ${errors.field ? "is-invalid" : ""}`}
                id="field"
                value={field}
                onChange={handlefieldChange}
                required
              />
              {errors.field && (
                <div className="invalid-feedback">{errors.field}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="degree" className="form-label">
                Degree&nbsp;<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className={`form-control ${errors.degree ? "is-invalid" : ""}`}
                id="degree"
                value={degree}
                onChange={handleDegreeChange}
                required
              >
                <option value="">Select Degree</option>
                <option value="B.Tech">B.Tech</option>
                <option value="B.Voc">B.Voc</option>
              </select>
              {errors.degree && (
                <div className="invalid-feedback">{errors.degree}</div>
              )}
            </div>
          </div>

          <div>
            <label className="form-label">
              Profile Photo&nbsp;<span style={{ color: "red" }}>*</span>{" "}
              <small className="text-muted"></small>
            </label>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setProfilePhoto("");
    setReferenceNumber("");
    setfield("");
    setDegree(""); 
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>

        <div className="col-md-5">
          <div className="main p-4 rounded shadow bg-light">
            <div className="card-body">
              {registrationSuccess ? (
                <div className="alert alert-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  Thank you for registration! You're now part of our community.
                  🎉
                </div>
              ) : (
                <form>
                  <div className="mb-1">
                    <div
                      className="text-center mb-1"
                      style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                    >
                      Choose your role for Register
                    </div>
                    <div className=" mb-0">
                      <div className=" btn-group d-flex text-center mb-1 px-1">
                        <button
                          type="button"
                          className={`btn ${
                            selectedTab === "Student"
                              ? "btn-dark"
                              : "btn-secondary"
                          }`}
                          onClick={() => handleTabChange("Student")}
                          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                        >
                          Student
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            selectedTab === "Instructor"
                              ? "btn-dark"
                              : "btn-secondary"
                          }`}
                          onClick={() => handleTabChange("Instructor")}
                          style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                        >
                          Instructor
                        </button>
                      </div>
                    </div>
                    <div
                      className="text-center mb-1 "
                      style={{
                        color: "dodgerblue",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      {selectedTab} Register
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Full Name&nbsp;<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email&nbsp;<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password&nbsp;<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEye : faEyeSlash}
                            />
                          </button>
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <div className="input-group">
                          <input
                            type="password"
                            className={`form-control ${
                              errors.confirmPassword ? "is-invalid" : ""
                            }`}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={toggleCPasswordVisibility}
                          >
                            <FontAwesomeIcon
                              icon={showCPassword ? faEye : faEyeSlash}
                            />
                          </button>
                        </div>

                        {errors.confirmPassword && (
                          <div className="invalid-feedback">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div> */}
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showCPassword ? "text" : "password"}
                            className={`form-control ${
                              errors.confirmPassword ? "is-invalid" : ""
                            }`}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={toggleCPasswordVisibility}
                          >
                            <FontAwesomeIcon
                              icon={showCPassword ? faEye : faEyeSlash}
                            />
                          </button>
                          {errors.confirmPassword && (
                            <div className="invalid-feedback">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-0">{renderProfilePhotoLabel()}</div>

                  <div className="row">
                    <div className="col-6 mb-1">{renderWebcamSection()}</div>
                    <div className="col-6 mb-3">
                      {selectedTab === "Student" && profilePhoto && (
                        <img
                          src={profilePhoto}
                          alt="Profile Photo"
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>

                  <div className="mb-1">
                    <button
                      type="button"
                      className="btn btn-dark btn-block"
                      style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                      onClick={handleRegister}
                      disabled={loading}
                    >
                      {loading ? (
                        <Vortex
                          height={20}
                          width={20}
                          color="white"
                          ariaLabel="loading"
                        />
                      ) : (
                        `${selectedTab} Register`
                      )}
                    </button>
                  </div>
                </form>
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    color: "#333",
                    
                    marginBottom: "10px",
                    
                  }}
                >
                  Already have an account?
                </p>
                <Link
                  to="/login"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "16px",
                    color: "#007bff",
                    textDecoration: "none",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.color = "#0056b3")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.color = "#007bff")
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "8px" }}
                  />
                  Login here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;

// import React, { useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCamera,
//   faEye,
//   faEyeSlash,
//   faCheckCircle,
//   faArrowLeft,
// } from "@fortawesome/free-solid-svg-icons";
// import Webcam from "react-webcam";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
// import { Vortex } from "react-loader-spinner";
// // import backgroundVideo from "../path/to/your/video.mp4";
// import videoSrc from "./aaaaaas.mp4";
// const RegistrationForm = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [profilePhoto, setProfilePhoto] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [showCPassword, setShowCPassword] = useState(false);
//   const [cameraOpen, setCameraOpen] = useState(true);
//   const [registrationSuccess, setRegistrationSuccess] = useState(false);
//   const [selectedTab, setSelectedTab] = useState("Student");
//   const [referenceNumber, setReferenceNumber] = useState("");
//   const [field, setField] = useState("");
//   const [degree, setDegree] = useState("");
//   const [faceDetectionLoading, setFaceDetectionLoading] = useState(false);
//   const [instructorReferenceSent, setInstructorReferenceSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});
//   const webcamRef = useRef(null);

//   const handleRegister = async () => {
//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       return;
//     }

//     setLoading(true);
//     setErrors({});

//     let registrationData = {
//       name,
//       email,
//       password,
//       profile_photo: profilePhoto,
//       role: selectedTab.toLowerCase(),
//     };

//     if (selectedTab === "Student") {
//       registrationData.instructor_reference = referenceNumber;
//       registrationData.field = field;
//       registrationData.degree = degree;
//     }

//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/registration/",
//         registrationData
//       );

//       if (response.data.success) {
//         setRegistrationSuccess(true);
//         clearForm();
//         if (selectedTab === "Instructor") {
//           await sendInstructorReferenceCode();
//         }
//       } else {
//         Swal.fire("Error", response.data.error, "error");
//       }
//     } catch (error) {
//       console.error("Error during registration:", error);
//       Swal.fire("Error", "An error occurred during registration.", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!name) errors.name = "Full Name is required.";
//     if (!email) errors.email = "Email is required.";
//     if (!password) errors.password = "Password is required.";
//     if (!confirmPassword)
//       errors.confirmPassword = "Confirm Password is required.";
//     if (password !== confirmPassword)
//       errors.confirmPassword = "Passwords do not match.";
//     if (selectedTab === "Student") {
//       if (!referenceNumber) {
//         errors.referenceNumber = "Reference Number is required.";
//       }
//       if (!profilePhoto) {
//         Swal.fire(
//           "Error",
//           "Profile photo and all fields are required for Student registration.",
//           "error"
//         );
//         errors.profilePhoto = "Profile photo is required.";
//       }
//       if (!field) errors.field = "Field is required.";
//       if (!degree) errors.degree = "Degree is required.";
//     }
//     return errors;
//   };

//   const toggleCamera = () => {
//     setCameraOpen(!cameraOpen);
//   };

//   const handleDegreeChange = (e) => {
//     setDegree(e.target.value);
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleCPasswordVisibility = () => {
//     setShowCPassword(!showCPassword);
//   };

//   const handleTabChange = (tab) => {
//     setSelectedTab(tab);
//     setErrors({});
//   };

//   const capture = async () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setProfilePhoto(imageSrc);
//     await detectFace(imageSrc);
//   };

//   const handleFieldChange = (e) => {
//     setField(e.target.value);
//   };

//   const handleReferenceNumberChange = (e) => {
//     setReferenceNumber(e.target.value);
//   };

//   const sendInstructorReferenceCode = async () => {
//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/send_reference_code/",
//         { email }
//       );

//       if (response.data.message) {
//         setInstructorReferenceSent(true);
//         Swal.fire({
//           icon: "success",
//           title: "Reference Code Sent",
//           text: "The reference number has been sent to your email. Please check.",
//         });
//       } else {
//         Swal.fire("Error", response.data.error, "error");
//       }
//     } catch (error) {
//       console.error("Error sending reference code:", error);
//       Swal.fire(
//         "Error",
//         "An error occurred while sending the reference code.",
//         "error"
//       );
//     }
//   };

//   const detectFace = async (imageSrc) => {
//     setFaceDetectionLoading(true);
//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/check_faces/",
//         { image: imageSrc }
//       );

//       const { result, number_of_faces } = response.data;

//       if (result) {
//         Swal.fire({
//           icon: "success",
//           title: "Face Captured",
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Please Recapture",
//           text: "Please try again.",
//         });
//         setProfilePhoto("");
//       }
//     } catch (error) {
//       console.error("Error detecting face:", error);
//       Swal.fire("Error", "Clear Face Required.", "error");
//     } finally {
//       setFaceDetectionLoading(false);
//     }
//   };

//   const renderWebcamSection = () => {
//     if (selectedTab === "Student") {
//       return (
//         <>
//           {cameraOpen ? (
//             <div style={{ position: "relative" }}>
//               <Webcam
//                 audio={false}
//                 style={{
//                   height: "200px",
//                   width: "280px",
//                 }}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//               />
//               <div
//                 style={{
//                   position: "absolute",
//                   top: 0,
//                   left: 0,
//                   width: "280px",
//                   height: "200px",
//                   zIndex: "99",
//                   backgroundImage:
//                     "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
//                   backgroundSize: "cover",
//                   opacity: "0.4",
//                 }}
//               ></div>
//               <button
//                 type="button"
//                 className="btn btn-outline-secondary mt-2"
//                 onClick={capture}
//               >
//                 <FontAwesomeIcon icon={faCamera} /> Capture
//               </button>{" "}
//             </div>
//           ) : (
//             <div className="text-muted">Camera is closed.</div>
//           )}
//           <button
//             type="button"
//             className="btn btn-outline-secondary mt-2"
//             onClick={toggleCamera}
//           >
//             <FontAwesomeIcon icon={cameraOpen ? faEyeSlash : faEye} />{" "}
//             {cameraOpen ? "Close Camera" : "Open Camera"}
//           </button>
//         </>
//       );
//     } else {
//       return null;
//     }
//   };

//   const renderProfilePhotoLabel = () => {
//     if (selectedTab === "Student") {
//       return (
//         <>
//           <div className="mb-3">
//             <label htmlFor="referenceNumber" className="form-label">
//               Reference Number&nbsp;<span style={{ color: "red" }}>*</span>
//             </label>
//             <input
//               type="number"
//               className={`form-control ${
//                 errors.referenceNumber ? "is-invalid" : ""
//               }`}
//               id="referenceNumber"
//               value={referenceNumber}
//               onChange={handleReferenceNumberChange}
//               required
//             />
//             {errors.referenceNumber && (
//               <div className="invalid-feedback">{errors.referenceNumber}</div>
//             )}
//           </div>
//           <div className="row">
//             <div className="col-md-6 mb-3">
//               <label htmlFor="field" className="form-label">
//                 Specialization&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="text"
//                 placeholder="Enter Specialization"
//                 className={`form-control ${errors.field ? "is-invalid" : ""}`}
//                 id="field"
//                 value={field}
//                 onChange={handleFieldChange}
//                 required
//               />
//               {errors.field && (
//                 <div className="invalid-feedback">{errors.field}</div>
//               )}
//             </div>
//             <div className="col-md-6 mb-3">
//               <label htmlFor="degree" className="form-label">
//                 Degree&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="text"
//                 placeholder="Enter Degree"
//                 className={`form-control ${errors.degree ? "is-invalid" : ""}`}
//                 id="degree"
//                 value={degree}
//                 onChange={handleDegreeChange}
//                 required
//               />
//               {errors.degree && (
//                 <div className="invalid-feedback">{errors.degree}</div>
//               )}
//             </div>
//           </div>
//         </>
//       );
//     } else {
//       return null;
//     }
//   };

//   const clearForm = () => {
//     setName("");
//     setEmail("");
//     setPassword("");
//     setConfirmPassword("");
//     setProfilePhoto("");
//     setReferenceNumber("");
//     setField("");
//     setDegree("");
//     setErrors({});
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         width: "100%",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "hidden",
//       }}
//     >
//       <video
//         autoPlay
//         muted
//         loop
//         id="background-video"
//         style={{
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           objectFit: "cover",
//         }}
//       >
//         <source src={videoSrc} type="video/mp4" />
//       </video>
//       <div
//         style={{
//           zIndex: 1,
//           background: "rgba(255, 255, 255, 0.8)",
//           padding: "20px",
//           borderRadius: "10px",
//           width: "100%",
//           maxWidth: "600px",
//         }}
//       >
//         {registrationSuccess ? (
//           <div style={{ textAlign: "center" }}>
//             <FontAwesomeIcon
//               icon={faCheckCircle}
//               className="text-success"
//               size="3x"
//             />
//             <h3>Registration Successful!</h3>
//             <Link to="/login" className="btn btn-primary">
//               <FontAwesomeIcon icon={faArrowLeft} /> Back to Login
//             </Link>
//           </div>
//         ) : (
//           <>
//             <div className="d-flex justify-content-center mb-3">
//               <button
//                 type="button"
//                 className={`btn ${
//                   selectedTab === "Student"
//                     ? "btn-primary"
//                     : "btn-outline-primary"
//                 }`}
//                 onClick={() => handleTabChange("Student")}
//               >
//                 Student
//               </button>
//               <button
//                 type="button"
//                 className={`btn ${
//                   selectedTab === "Instructor"
//                     ? "btn-primary"
//                     : "btn-outline-primary"
//                 }`}
//                 onClick={() => handleTabChange("Instructor")}
//               >
//                 Instructor
//               </button>
//             </div>
//             <form>
//               <h2 style={{ textAlign: "center" }}>Registration</h2>
//               <div className="mb-3">
//                 <label htmlFor="name" className="form-label">
//                   Full Name&nbsp;<span style={{ color: "red" }}>*</span>
//                 </label>
//                 <input
//                   type="text"
//                   className={`form-control ${errors.name ? "is-invalid" : ""}`}
//                   id="name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                   required
//                 />
//                 {errors.name && (
//                   <div className="invalid-feedback">{errors.name}</div>
//                 )}
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="email" className="form-label">
//                   Email&nbsp;<span style={{ color: "red" }}>*</span>
//                 </label>
//                 <input
//                   type="email"
//                   className={`form-control ${errors.email ? "is-invalid" : ""}`}
//                   id="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                 />
//                 {errors.email && (
//                   <div className="invalid-feedback">{errors.email}</div>
//                 )}
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="password" className="form-label">
//                   Password&nbsp;<span style={{ color: "red" }}>*</span>
//                 </label>
//                 <div className="input-group">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     className={`form-control ${
//                       errors.password ? "is-invalid" : ""
//                     }`}
//                     id="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                   <button
//                     type="button"
//                     className="btn btn-outline-secondary"
//                     onClick={togglePasswordVisibility}
//                   >
//                     <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//                   </button>
//                   {errors.password && (
//                     <div className="invalid-feedback d-block">
//                       {errors.password}
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="confirmPassword" className="form-label">
//                   Confirm Password&nbsp;<span style={{ color: "red" }}>*</span>
//                 </label>
//                 <div className="input-group">
//                   <input
//                     type={showCPassword ? "text" : "password"}
//                     className={`form-control ${
//                       errors.confirmPassword ? "is-invalid" : ""
//                     }`}
//                     id="confirmPassword"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                   <button
//                     type="button"
//                     className="btn btn-outline-secondary"
//                     onClick={toggleCPasswordVisibility}
//                   >
//                     <FontAwesomeIcon
//                       icon={showCPassword ? faEyeSlash : faEye}
//                     />
//                   </button>
//                   {errors.confirmPassword && (
//                     <div className="invalid-feedback d-block">
//                       {errors.confirmPassword}
//                     </div>
//                   )}
//                 </div>
//               </div>
//               {renderProfilePhotoLabel()}
//               {renderWebcamSection()}
//               <div style={{ textAlign: "center" }}>
//                 <button
//                   type="button"
//                   className="btn btn-primary"
//                   onClick={handleRegister}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <Vortex
//                       height="20"
//                       width="20"
//                       color="#fff"
//                       ariaLabel="loading"
//                     />
//                   ) : (
//                     "Register"
//                   )}
//                 </button>
//               </div>
//             </form>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default RegistrationForm;

// import React, { useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCamera,
//   faEye,
//   faEyeSlash,
//   faCheckCircle,
//   faArrowLeft,
// } from "@fortawesome/free-solid-svg-icons";
// import bregster from "../Pages/bregster.png";
// import Webcam from "react-webcam";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
// import { Vortex } from "react-loader-spinner";

// const RegistrationForm = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [profilePhoto, setProfilePhoto] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [showCPassword, setShowCPassword] = useState(false);

//   const [cameraOpen, setCameraOpen] = useState(true);
//   const [registrationSuccess, setRegistrationSuccess] = useState(false);
//   const [selectedTab, setSelectedTab] = useState("Student");
//   const [referenceNumber, setReferenceNumber] = useState("");
//   const [field, setfield] = useState("");
//   const [degree, setDegree] = useState("");
//   const [faceDetectionLoading, setFaceDetectionLoading] = useState(false);

//   const [instructorReferenceSent, setInstructorReferenceSent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});
//   const webcamRef = useRef(null);

//   const handleRegister = async () => {
//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       return;
//     }

//     setLoading(true);
//     setErrors({});

//     let registrationData = {
//       name,
//       email,
//       password,
//       profile_photo: profilePhoto,
//       role: selectedTab.toLowerCase(),
//     };

//     if (selectedTab === "Student") {
//       registrationData.instructor_reference = referenceNumber;
//       registrationData.field = field;
//       registrationData.degree = degree;
//     }

//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/registration/",
//         registrationData
//       );

//       if (response.data.success) {
//         setRegistrationSuccess(true);
//         clearForm();
//         if (selectedTab === "Instructor") {
//           await sendInstructorReferenceCode();
//         }
//       } else {
//         Swal.fire("Error", response.data.error, "error");
//       }
//     } catch (error) {
//       console.error("Error during registration:", error);
//       Swal.fire("Error", "An error occurred during registration.", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!name) errors.name = "Full Name is required.";
//     if (!email) errors.email = "Email is required.";
//     if (!password) errors.password = "Password is required.";
//     if (!confirmPassword)
//       errors.confirmPassword = "Confirm Password is required.";
//     if (password !== confirmPassword)
//       errors.confirmPassword = "Passwords do not match.";
//     if (selectedTab === "Student") {
//       if (!referenceNumber) {
//         errors.referenceNumber = "Reference Number is required.";
//       }
//       if (!profilePhoto) {
//         Swal.fire(
//           "Error",
//           "Profile photo and all fields are required for Student registration.",
//           "error"
//         );
//         errors.profilePhoto = "Profile photo is required.";
//       }
//       if (!field) errors.field = "field is required.";
//       if (!degree) errors.degree = "Degree is required.";
//     }
//     return errors;
//   };
//   const toggleCamera = () => {
//     setCameraOpen(!cameraOpen);
//   };
//   const handleDegreeChange = (e) => {
//     setDegree(e.target.value);
//   };
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };
//   const toggleCPasswordVisibility = () => {
//     setShowCPassword(!showCPassword);
//   };

//   const handleTabChange = (tab) => {
//     setSelectedTab(tab);
//     setErrors({});
//   };

//   const capture = async () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setProfilePhoto(imageSrc);
//     await detectFace(imageSrc);
//   };
//   const handlefieldChange = (e) => {
//     setfield(e.target.value);
//   };
//   const handleReferenceNumberChange = (e) => {
//     setReferenceNumber(e.target.value);
//   };

//   const sendInstructorReferenceCode = async () => {
//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/send_reference_code/",
//         { email }
//       );

//       if (response.data.message) {
//         setInstructorReferenceSent(true);
//         Swal.fire({
//           icon: "success",
//           title: "Reference Code Sent",
//           text: "The reference number has been sent to your email. Please check.",
//         });
//       } else {
//         Swal.fire("Error", response.data.error, "error");
//       }
//     } catch (error) {
//       console.error("Error sending reference code:", error);
//       Swal.fire(
//         "Error",
//         "An error occurred while sending the reference code.",
//         "error"
//       );
//     }
//   };
//   const detectFace = async (imageSrc) => {
//     setFaceDetectionLoading(true);
//     try {
//       const response = await axios.post(
//         "https://newaaas.tdtlworld.com/check_faces/",
//         { image: imageSrc }
//       );

//       const { result, number_of_faces } = response.data;

//       if (result) {
//         Swal.fire({
//           icon: "success",
//           title: "Face Captured",
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Please Recapture",
//           text: "Please try again.",
//         });
//         setProfilePhoto("");
//       }
//     } catch (error) {
//       console.error("Error detecting face:", error);
//       Swal.fire("Error", "Clear Face Required.", "error");
//     } finally {
//       setFaceDetectionLoading(false);
//     }
//   };

//   const renderWebcamSection = () => {
//     if (selectedTab === "Student") {
//       return (
//         <>
//           {cameraOpen ? (
//             <div style={{ position: "relative" }}>
//               <Webcam
//                 audio={false}
//                 style={{
//                   height: "200px",
//                   width: "280px",
//                 }}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//               />
//               <div
//                 style={{
//                   position: "absolute",
//                   top: 0,
//                   left: 0,
//                   width: "280px",
//                   height: "200px",
//                   zIndex: "99",
//                   backgroundImage:
//                     "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
//                   backgroundSize: "cover",
//                   opacity: "0.4",
//                 }}
//               ></div>
//               <button
//                 type="button"
//                 className="btn btn-outline-secondary mt-2"
//                 onClick={capture}
//               >
//                 <FontAwesomeIcon icon={faCamera} /> Capture
//               </button>{" "}
//             </div>
//           ) : (
//             <div className="text-muted">Camera is closed.</div>
//           )}
//           <button
//             type="button"
//             className="btn btn-outline-secondary mt-2"
//             onClick={toggleCamera}
//           >
//             <FontAwesomeIcon icon={cameraOpen ? faEyeSlash : faEye} />{" "}
//             {cameraOpen ? "Close Camera" : "Open Camera"}
//           </button>
//         </>
//       );
//     } else {
//       return null;
//     }
//   };
//   const renderProfilePhotoLabel = () => {
//     if (selectedTab === "Student") {
//       return (
//         <>
//           <div className="mb-3">
//             <label htmlFor="referenceNumber" className="form-label">
//               Reference Number&nbsp;<span style={{ color: "red" }}>*</span>
//             </label>
//             <input
//               type="number"
//               className={`form-control ${
//                 errors.referenceNumber ? "is-invalid" : ""
//               }`}
//               id="referenceNumber"
//               value={referenceNumber}
//               onChange={handleReferenceNumberChange}
//               required
//             />
//             {errors.referenceNumber && (
//               <div className="invalid-feedback">{errors.referenceNumber}</div>
//             )}
//           </div>
//           <div className="row">
//             <div className="col-md-6 mb-3">
//               <label htmlFor="field" className="form-label">
//                 Specialization&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="text"
//                 placeholder="Enter Specialization"
//                 className={`form-control ${errors.field ? "is-invalid" : ""}`}
//                 id="field"
//                 value={field}
//                 onChange={handlefieldChange}
//                 required
//               />
//               {errors.field && (
//                 <div className="invalid-feedback">{errors.field}</div>
//               )}
//             </div>
//             <div className="col-md-6 mb-3">
//               <label htmlFor="degree" className="form-label">
//                 Degree&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="text"
//                 placeholder="Enter Degree"
//                 className={`form-control ${errors.degree ? "is-invalid" : ""}`}
//                 id="degree"
//                 value={degree}
//                 onChange={handleDegreeChange}
//                 required
//               />
//               {errors.degree && (
//                 <div className="invalid-feedback">{errors.degree}</div>
//               )}
//             </div>
//           </div>
//           <div className="form-group">
//             <label className="mt-3">Profile Photo</label>
//             <div className="profile-photo-container">
//               {profilePhoto ? (
//                 <div className="text-center">
//                   <img
//                     src={profilePhoto}
//                     alt="Profile"
//                     className="rounded-circle"
//                     style={{
//                       width: "120px",
//                       height: "120px",
//                       objectFit: "cover",
//                       border: "2px solid #ddd",
//                       padding: "2px",
//                     }}
//                   />
//                   <button
//                     type="button"
//                     className="btn btn-outline-secondary btn-block mt-2"
//                     onClick={() => setProfilePhoto("")}
//                   >
//                     Recapture Photo
//                   </button>
//                 </div>
//               ) : (
//                 renderWebcamSection()
//               )}
//               {errors.profilePhoto && (
//                 <div className="invalid-feedback d-block">
//                   {errors.profilePhoto}
//                 </div>
//               )}
//             </div>
//           </div>
//         </>
//       );
//     } else {
//       return null;
//     }
//   };

//   const clearForm = () => {
//     setName("");
//     setEmail("");
//     setPassword("");
//     setConfirmPassword("");
//     setProfilePhoto("");
//     setReferenceNumber("");
//     setfield("");
//     setDegree("");
//   };

//   const renderLoader = () => {
//     if (loading) {
//       return (
//         <div
//           className="loading-overlay"
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(255, 255, 255, 0.8)",
//             zIndex: 9999,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Vortex
//             visible={true}
//             height="100"
//             width="100"
//             ariaLabel="vortex-loading"
//             wrapperStyle={{}}
//             wrapperClass="vortex-wrapper"
//             colors={["red", "green", "blue", "yellow", "orange", "purple"]}
//           />
//         </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//         backgroundImage: `url(${bregster})`,
//         backgroundSize: "cover",
//       }}
//     >
//       {renderLoader()}
//       {registrationSuccess ? (
//         <div className="card shadow-lg p-3 mb-5 bg-white rounded">
//           <div className="card-body text-center">
//             <FontAwesomeIcon
//               icon={faCheckCircle}
//               className="text-success"
//               size="3x"
//             />
//             <h5 className="card-title mt-3">Registration Successful!</h5>
//             <p className="card-text">Thank you for registering.</p>
//             <Link to="/login" className="btn btn-primary">
//               Go to Login
//             </Link>
//           </div>
//         </div>
//       ) : (
//         <div
//           className="card p-4 shadow-lg"
//           style={{ width: "400px", backgroundColor: "#ffffffc7" }}
//         >
//           <h2 className="mb-4 text-center">Registration</h2>
//           <div className="d-flex justify-content-around mb-4">
//             <button
//               className={`btn ${
//                 selectedTab === "Student"
//                   ? "btn-primary"
//                   : "btn-outline-primary"
//               }`}
//               onClick={() => handleTabChange("Student")}
//             >
//               Student
//             </button>
//             <button
//               className={`btn ${
//                 selectedTab === "Instructor"
//                   ? "btn-primary"
//                   : "btn-outline-primary"
//               }`}
//               onClick={() => handleTabChange("Instructor")}
//             >
//               Instructor
//             </button>
//           </div>
//           <form>
//             <div className="mb-3">
//               <label htmlFor="name" className="form-label">
//                 Full Name&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="text"
//                 className={`form-control ${errors.name ? "is-invalid" : ""}`}
//                 id="name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 required
//               />
//               {errors.name && (
//                 <div className="invalid-feedback">{errors.name}</div>
//               )}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="email" className="form-label">
//                 Email&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <input
//                 type="email"
//                 className={`form-control ${errors.email ? "is-invalid" : ""}`}
//                 id="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               {errors.email && (
//                 <div className="invalid-feedback">{errors.email}</div>
//               )}
//             </div>
//             <div className="mb-3">
//               <label htmlFor="password" className="form-label">
//                 Password&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <div className="input-group">
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   className={`form-control ${
//                     errors.password ? "is-invalid" : ""
//                   }`}
//                   id="password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-outline-secondary"
//                   onClick={togglePasswordVisibility}
//                 >
//                   <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
//                 </button>
//                 {errors.password && (
//                   <div className="invalid-feedback">{errors.password}</div>
//                 )}
//               </div>
//             </div>
//             <div className="mb-3">
//               <label htmlFor="confirmPassword" className="form-label">
//                 Confirm Password&nbsp;<span style={{ color: "red" }}>*</span>
//               </label>
//               <div className="input-group">
//                 <input
//                   type={showCPassword ? "text" : "password"}
//                   className={`form-control ${
//                     errors.confirmPassword ? "is-invalid" : ""
//                   }`}
//                   id="confirmPassword"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                   required
//                 />
//                 <button
//                   type="button"
//                   className="btn btn-outline-secondary"
//                   onClick={toggleCPasswordVisibility}
//                 >
//                   <FontAwesomeIcon icon={showCPassword ? faEyeSlash : faEye} />
//                 </button>
//                 {errors.confirmPassword && (
//                   <div className="invalid-feedback">
//                     {errors.confirmPassword}
//                   </div>
//                 )}
//               </div>
//             </div>
//             {renderProfilePhotoLabel()}
//             <button
//               type="button"
//               className="btn btn-primary btn-block"
//               onClick={handleRegister}
//             >
//               Register
//             </button>
//           </form>
//         </div>
//       )}
//       <Link
//         to="/"
//         className="btn btn-secondary mt-3"
//         style={{ position: "absolute", top: "10px", left: "10px" }}
//       >
//         <FontAwesomeIcon icon={faArrowLeft} /> Go Back
//       </Link>
//     </div>
//   );
// };

// export default RegistrationForm;
