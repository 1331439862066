import { useRef, useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import { Vortex } from "react-loader-spinner";
import {  Navbar, Offcanvas } from "react-bootstrap";

export default function StartExam() {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Toggle sidebar for mobile view
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [examData, setExamData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [remainingTime, setRemainingTime] = useState(0);
  const [attemptedQuestions, setAttemptedQuestions] = useState([]);
  const [webcamStream, setWebcamStream] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [timeIsOver, setTimeIsOver] = useState(false); // New state variable
  const [showQuestions, setShowQuestions] = useState(false);

  const [numFaces, setNumFaces] = useState(0);

  const webCamRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(sendRequest, 1000);
    return () => clearInterval(interval);
  }, []);

  const captureVideoFeed = () => {
    try {
      const image = webCamRef.current.getScreenshot();
      return image;
    } catch (error) {
      console.error("Error capturing video feed:", error);
      return null;
    }
  };

  const dataURLtoBlob = (dataurl) => {
    try {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    } catch (error) {
      console.error("Error converting data URL to Blob:", error);
      return null;
    }
  };
  const examPhoto = localStorage.getItem("photo");

  let [tempTime, setTempTime] = useState("");
  useEffect(() => {
    setRemainingTime(tempTime);
  }, [tempTime]);
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    const exam_id = localStorage.getItem("exam_id");

    fetch("https://newaaas.tdtlworld.com/fetch_exam_details/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        exam_id: exam_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setExamData(data.data);
        let temp = parseInt(data.data[0].duration) * 60;
        setTempTime(temp);

        document.addEventListener("click", requestFullscreen);
      })
      .catch((error) => {
        console.error("Error fetching exam details:", error);
      });
    document.addEventListener("selectstart", disableTextSelection);
    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("click", requestFullscreen);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("selectstart", disableTextSelection);
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const disableTextSelection = (event) => {
    event.preventDefault();
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  const requestFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen().catch((error) => {
        console.error("Error entering fullscreen mode:", error);
      });
    } else {
      console.error("Fullscreen mode is not supported by this browser.");
    }
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      Swal.fire({
        title: "Last Warning",
        text: "Switching tabs during the exam is not allowed;\n if a tab change is detected, the exam will be submitted automatically.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
      });
    } else {
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.key === "PrintScreen" &&
          (event.metaKey || event.ctrlKey || event.altKey)) || // Windows/Linux
        (event.key === "F12" && event.metaKey) || // Developer tools (F12) on macOS
        (event.key === "F12" && event.altKey) || // Developer tools (F12) on macOS (Alt is used for screenshots on macOS)
        (event.key === "F14" && event.ctrlKey) || // Developer tools (F14) on macOS (Ctrl is used for screenshots on macOS)
        (event.ctrlKey && event.key === "c") || // Copy (Ctrl + C)
        (event.ctrlKey && event.key === "u") || // View page source (Ctrl + U)
        (event.ctrlKey && event.key === "s") || // Save (Ctrl + S)
        (event.altKey && event.key === "PrintScreen") || // Screenshot of active window (Alt + PrintScreen) on Windows/Linux
        (event.shiftKey && event.key === "PrintScreen") || // Screenshot of selected area (Shift + PrintScreen) on Windows/Linux
        (event.shiftKey && event.metaKey && event.key === "3") || // Screenshot of entire screen (Shift + Command + 3) on macOS
        (event.shiftKey && event.metaKey && event.key === "4") || // Screenshot of selected area (Shift + Command + 4) on macOS
        (event.shiftKey &&
          event.metaKey &&
          event.key === "4" &&
          event.spaceKey) || // Screenshot of specific window (Shift + Command + 4 + Space) on macOS
        (event.key === "PrintScreen" && event.metaKey) || // Windows Logo Key + PrintScreen
        (event.ctrlKey && event.shiftKey && event.key === "s") // Ctrl + Shift + S
      ) {
        event.preventDefault();

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Taking screenshots during the exam is not allowed!",
        });
      }
    };

    // Add event listener for keydown events
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      exitFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen().catch((error) => {
        console.error("Error exiting fullscreen mode:", error);
      });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          setTimeIsOver(true);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // const handleOptionChange = (questionId, optionId) => {
  //   if (!selectedOptions[questionId]) {
  //     setSelectedOptions({
  //       ...selectedOptions,
  //       [questionId]: [optionId],
  //     });
  //   } else {
  //     const updatedOptions = selectedOptions[questionId].includes(optionId)
  //       ? selectedOptions[questionId].filter((id) => id !== optionId)
  //       : [...selectedOptions[questionId], optionId];
  //     setSelectedOptions({
  //       ...selectedOptions,
  //       [questionId]: updatedOptions,
  //     });
  //   }

  //   if (!attemptedQuestions.includes(questionId)) {
  //     setAttemptedQuestions([...attemptedQuestions, questionId]);
  //   }
  // };

  const handleOptionChange = (questionId, optionId) => {
    const question = examData[0].questions.find(q => q.question_id === questionId);
  
    // For single-choice questions, only allow one option to be selected
    if (question.type_id === 1) {
      setSelectedOptions({
        ...selectedOptions,
        [questionId]: [optionId], // Replace the selection with the new option
      });
    } else {
      // For multiple-choice questions, toggle the selected option
      const updatedOptions = selectedOptions[questionId]?.includes(optionId)
        ? selectedOptions[questionId].filter((id) => id !== optionId) // Remove if already selected
        : [...(selectedOptions[questionId] || []), optionId]; // Add if not selected
  
      setSelectedOptions({
        ...selectedOptions,
        [questionId]: updatedOptions,
      });
    }
  
    // Mark the question as attempted if it's not already in the attemptedQuestions array
    if (!attemptedQuestions.includes(questionId)) {
      setAttemptedQuestions([...attemptedQuestions, questionId]);
    }
  };
  
  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleSubmitExam = () => {
    if (isFullscreen) {
      exitFullscreen();
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Once submitted, you will not be able to change your answers!",
      icon: "warning",
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(true); // Set loading state to true
        const user_id = localStorage.getItem("user_id");
        const exam_id = localStorage.getItem("exam_id");

        const submissions = attemptedQuestions.map((questionId) => {
          const option_id = selectedOptions[questionId];
          const question = examData[0].questions.find(
            (question) => question.question_id === questionId
          );
          const option = question
            ? question.options.find((option) => option.option_id === option_id)
            : null;

          return {
            exam_id: exam_id,
            user_id: user_id,
            question_id: questionId,
            option_id: option_id,
          };
        });

        fetch("https://newaaas.tdtlworld.com/exam_result/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissions),
        })
          .then((response) => {
            if (response.ok) {
              Swal.fire(
                "Submitted!",
                "Your exam has been submitted successfully.",
                "success"
              ).then(() => {
                setIsSubmitting(false); // Unset loading state
                navigate("/examComplete");
              });
            } else {
              console.error(`Error submitting exam: ${response.statusText}`);
              setIsSubmitting(false); // Unset loading state
            }
          })
          .catch((error) => {
            console.error(`Error submitting exam: ${error}`);
            setIsSubmitting(false); // Unset loading state
          });
      }
    });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes == "00" && remainingSeconds == "00") {
      handleSubmitExam();
    }
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY,
      });
    }
  };

  const handleMouseUp = (event) => {
    setIsDragging(false);
  };
  const sendRequest = async () => {
    const image = captureVideoFeed();
    if (!image) return;

    const imageBlob = dataURLtoBlob(image);
    if (!imageBlob) return;

    const formData = new FormData();
    formData.append("image", imageBlob);
    formData.append("profile", examPhoto);

    const url = "https://newaaas.tdtlworld.com/detect_faces/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.verfied) {
          // Swal.fire({
          //   icon: "error",
          //   text: "invalid user",
          // });
          return;
        }
        if (data.number_of_faces >= 2) {
          Swal.fire("Alert!", "More than 2 People detected", "warning");
        }

        setNumFaces(data.number_of_faces);
      }
    } catch (error) {
      console.error("Error detecting faces:", error);
    }
  };

  return (
    <div
    style={{
      display: "flex",
      height: "100vh",
      background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
    }}
  >
    {/* Sidebar - visible by default on desktop, toggleable on mobile */}
    <div
      style={{
        flex: "0 0 25%",
        backgroundColor: "#fff",
        padding: "20px",
        overflow: "auto",
        display: isSidebarOpen || window.innerWidth >= 768 ? "block" : "none",
      }}
    >
      <h4>Question List</h4>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        {examData &&
          examData[0].questions.map((question, index) => (
            <li
              key={index}
              style={{
                flex: "0 0 33.33%",
                maxWidth: "30.33%",
                marginBottom: "5px",
              }}
            >
              <button
                style={{
                  backgroundColor: attemptedQuestions.includes(
                    question.question_id
                  )
                    ? "#28a745"
                    : currentQuestionIndex === index
                    ? "#ffc107"
                    : "transparent",
                  color: attemptedQuestions.includes(question.question_id)
                    ? "#fff"
                    : "#000",
                  border: "1px solid #ccc",
                  padding: "10px",
                  width: "calc(100% - 20px)",
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "5px",
                  transition:
                    "background-color 0.3s ease, border-color 0.3s ease",
                }}
                onClick={() => setCurrentQuestionIndex(index)}
              >
                {index + 1}
              </button>
            </li>
          ))}
      </ul>
    </div>

    {/* Main content */}
    <div style={{ flex: "1", padding: "20px" }}>
      {/* Hamburger button for mobile view */}
      <div style={{ display: window.innerWidth < 768 ? "block" : "none", marginBottom: "20px" }}>
        <button onClick={toggleSidebar} style={{ fontSize: "24px", background: "transparent", border: "none", cursor: "pointer" }}>
          &#9776;
        </button>
      </div>

      {examData ? (
        <>
          <h2 style={{ color: "#fff", textAlign: "center" }}>{examData[0].title}</h2>
          {/* Exam details and question list */}
          <div className="row justify-content-center">
            <div className="col-md-6">
              <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                <Card.Body style={{ display: "flex" }}>
                  <div style={{ flex: "1", paddingRight: "20px" }}>
                    <Card.Title className="text-primary">Exam Details</Card.Title>
                    <ul className="list-unstyled">
                      <li><strong>Start Date:</strong> {examData[0].start_date}</li>
                      <li><strong>Marks:</strong> {examData[0].marks}</li>
                      <li><strong>Description:</strong> {examData[0].description}</li>
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                <Card.Body>
                  <Card.Title className="text-primary">Exam Timing</Card.Title>
                  <div className="row">
                    <div className="col">
                      <ul className="list-unstyled">
                        <li><strong>Time:</strong> {examData[0].time}</li>
                        <li><strong>Remaining Time:</strong> <span className="text-danger">{formatTime(remainingTime)}</span></li>
                      </ul>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          {/* Render each question */}
          {examData[0].questions.map((question, index) => (
            <div key={question.question_id} style={{ display: index === currentQuestionIndex ? "block" : "none" }}>
              <Card style={{ marginBottom: "20px" }}>
                <Card.Body>
                  <Card.Title style={{ color: "#007bff" }}>Question {currentQuestionIndex + 1} ({question.question_marks} mark(s))</Card.Title>
                  <Card.Text style={{ color: "#333" }}>{question.question_description}</Card.Text>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {question.options.map((option) => (
                      <li key={option.option_id} style={{ marginBottom: "10px" }}>
                        <input
                          type={question.type_id === 1 ? "radio" : "checkbox"}
                          id={`option_${option.option_id}`}
                          name={`question_${question.question_id}`}
                          value={option.option_id}
                          checked={selectedOptions[question.question_id] && selectedOptions[question.question_id].includes(option.option_id)}
                          onChange={() => handleOptionChange(question.question_id, option.option_id)}
                        />
                        <label htmlFor={`option_${option.option_id}`} style={{ marginLeft: "5px", color: "#333" }}>{option.option}</label>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </div>
          ))}
          {/* Navigation buttons */}
          <div style={{ textAlign: "center" }}>
            <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0} style={{ marginRight: "10px", backgroundColor: "#dc3545", borderColor: "#dc3545" }}>Previous Question</Button>{" "}
            <Button onClick={handleNextQuestion} disabled={currentQuestionIndex === examData[0].questions.length - 1} style={{ marginRight: "10px", backgroundColor: "#28a745", borderColor: "#28a745" }}>Next Question</Button>{" "}
            <Button onClick={handleSubmitExam} style={{ backgroundColor: "#007bff", borderColor: "#007bff" }} disabled={isSubmitting}>{isSubmitting ? <Vortex height="25" width="25" color="#ffffff" ariaLabel="vortex-loading" /> : "Submit Exam"}</Button>
          </div>
        </>
      ) : (
        <p style={{ textAlign: "center", color: "#333" }}>Loading exam details...</p>
      )}
    </div>
  </div>
  );
}






// import { useRef, useState, useEffect } from "react";
// import { Card, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import Webcam from "react-webcam";
// import { Vortex } from "react-loader-spinner";
 
// export default function StartExam() {
//   const navigate = useNavigate();
 
//   const [isSubmitting, setIsSubmitting] = useState(false);
 
//   const [examData, setExamData] = useState(null);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [remainingTime, setRemainingTime] = useState(0);
//   const [attemptedQuestions, setAttemptedQuestions] = useState([]);
//   const [webcamStream, setWebcamStream] = useState(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const [isFullscreen, setIsFullscreen] = useState(false);
//   const [timeIsOver, setTimeIsOver] = useState(false); // New state variable
 
//   const [numFaces, setNumFaces] = useState(0);
 
//   const webCamRef = useRef(null);
 
//   useEffect(() => {
//     const interval = setInterval(sendRequest, 1000);
//     return () => clearInterval(interval);
//   }, []);
 
//   const captureVideoFeed = () => {
//     try {
//       const image = webCamRef.current.getScreenshot();
//       return image;
//     } catch (error) {
//       console.error("Error capturing video feed:", error);
//       return null;
//     }
//   };
 
//   const dataURLtoBlob = (dataurl) => {
//     try {
//       const arr = dataurl.split(",");
//       const mime = arr[0].match(/:(.*?);/)[1];
//       const bstr = atob(arr[1]);
//       let n = bstr.length;
//       const u8arr = new Uint8Array(n);
//       while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//       }
//       return new Blob([u8arr], { type: mime });
//     } catch (error) {
//       console.error("Error converting data URL to Blob:", error);
//       return null;
//     }
//   };
//   const examPhoto = localStorage.getItem("photo");
 
//   let [tempTime, setTempTime] = useState("");
//   useEffect(() => {
//     setRemainingTime(tempTime);
//   }, [tempTime]);
//   useEffect(() => {
//     const user_id = localStorage.getItem("user_id");
//     const exam_id = localStorage.getItem("exam_id");
 
//     fetch("https://newaaas.tdtlworld.com/fetch_exam_details/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         user_id: user_id,
//         exam_id: exam_id,
//       }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setExamData(data.data);
//         let temp = parseInt(data.data[0].duration) * 60;
//         setTempTime(temp);
 
//         document.addEventListener("click", requestFullscreen);
//       })
//       .catch((error) => {
//         console.error("Error fetching exam details:", error);
//       });
//     document.addEventListener("selectstart", disableTextSelection);
//     document.addEventListener("contextmenu", disableRightClick);
//     document.addEventListener("visibilitychange", handleVisibilityChange);
 
//     return () => {
//       document.removeEventListener("click", requestFullscreen);
//       document.removeEventListener("keydown", handleKeyDown);
//       document.removeEventListener("selectstart", disableTextSelection);
//       document.removeEventListener("contextmenu", disableRightClick);
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, []);
 
//   const disableTextSelection = (event) => {
//     event.preventDefault();
//   };
 
//   const disableRightClick = (event) => {
//     event.preventDefault();
//   };
 
//   const requestFullscreen = () => {
//     if (document.documentElement.requestFullscreen) {
//       document.documentElement.requestFullscreen().catch((error) => {
//         console.error("Error entering fullscreen mode:", error);
//       });
//     } else {
//       console.error("Fullscreen mode is not supported by this browser.");
//     }
//   };
 
//   const handleVisibilityChange = () => {
//     if (document.hidden) {
//       Swal.fire({
//         title: "Last Warning",
//         text: "Switching tabs during the exam is not allowed;\n if a tab change is detected, the exam will be submitted automatically.",
//         icon: "warning",
//         confirmButtonColor: "#3085d6",
//       });
//     } else {
//     }
//   };
 
//   useEffect(() => {
//     document.addEventListener("visibilitychange", handleVisibilityChange);
 
//     return () => {
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, []);
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (
//         (event.key === "PrintScreen" &&
//           (event.metaKey || event.ctrlKey || event.altKey)) || // Windows/Linux
//         (event.key === "F12" && event.metaKey) || // Developer tools (F12) on macOS
//         (event.key === "F12" && event.altKey) || // Developer tools (F12) on macOS (Alt is used for screenshots on macOS)
//         (event.key === "F14" && event.ctrlKey) || // Developer tools (F14) on macOS (Ctrl is used for screenshots on macOS)
//         (event.ctrlKey && event.key === "c") || // Copy (Ctrl + C)
//         (event.ctrlKey && event.key === "u") || // View page source (Ctrl + U)
//         (event.ctrlKey && event.key === "s") || // Save (Ctrl + S)
//         (event.altKey && event.key === "PrintScreen") || // Screenshot of active window (Alt + PrintScreen) on Windows/Linux
//         (event.shiftKey && event.key === "PrintScreen") || // Screenshot of selected area (Shift + PrintScreen) on Windows/Linux
//         (event.shiftKey && event.metaKey && event.key === "3") || // Screenshot of entire screen (Shift + Command + 3) on macOS
//         (event.shiftKey && event.metaKey && event.key === "4") || // Screenshot of selected area (Shift + Command + 4) on macOS
//         (event.shiftKey &&
//           event.metaKey &&
//           event.key === "4" &&
//           event.spaceKey) || // Screenshot of specific window (Shift + Command + 4 + Space) on macOS
//         (event.key === "PrintScreen" && event.metaKey) || // Windows Logo Key + PrintScreen
//         (event.ctrlKey && event.shiftKey && event.key === "s") // Ctrl + Shift + S
//       ) {
//         event.preventDefault();
 
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Taking screenshots during the exam is not allowed!",
//         });
//       }
//     };
 
//     // Add event listener for keydown events
//     document.addEventListener("keydown", handleKeyDown);
 
//     // Cleanup function to remove the event listener when the component unmounts
//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);
//   const handleKeyDown = (event) => {
//     if (event.key === "Escape") {
//       exitFullscreen();
//     }
//   };
 
//   const exitFullscreen = () => {
//     if (document.exitFullscreen) {
//       document.exitFullscreen().catch((error) => {
//         console.error("Error exiting fullscreen mode:", error);
//       });
//     }
//   };
 
//   useEffect(() => {
//     const timer = setInterval(() => {
//       setRemainingTime((prevTime) => {
//         if (prevTime === 0) {
//           clearInterval(timer);
//           setTimeIsOver(true);
//           return 0;
//         } else {
//           return prevTime - 1;
//         }
//       });
//     }, 1000);
 
//     return () => clearInterval(timer);
//   }, []);
 
//   const handleOptionChange = (questionId, optionId) => {
//     if (!selectedOptions[questionId]) {
//       setSelectedOptions({
//         ...selectedOptions,
//         [questionId]: [optionId],
//       });
//     } else {
//       const updatedOptions = selectedOptions[questionId].includes(optionId)
//         ? selectedOptions[questionId].filter((id) => id !== optionId)
//         : [...selectedOptions[questionId], optionId];
//       setSelectedOptions({
//         ...selectedOptions,
//         [questionId]: updatedOptions,
//       });
//     }
 
//     if (!attemptedQuestions.includes(questionId)) {
//       setAttemptedQuestions([...attemptedQuestions, questionId]);
//     }
//   };
 
//   const handleNextQuestion = () => {
//     setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//   };
 
//   const handlePreviousQuestion = () => {
//     setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
//   };
 
//   const handleSubmitExam = () => {
//     if (isFullscreen) {
//       exitFullscreen();
//     }
 
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Once submitted, you will not be able to change your answers!",
//       icon: "warning",
//       // showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, submit it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         setIsSubmitting(true); // Set loading state to true
//         const user_id = localStorage.getItem("user_id");
//         const exam_id = localStorage.getItem("exam_id");
 
//         const submissions = attemptedQuestions.map((questionId) => {
//           const option_id = selectedOptions[questionId];
//           const question = examData[0].questions.find(
//             (question) => question.question_id === questionId
//           );
//           const option = question
//             ? question.options.find((option) => option.option_id === option_id)
//             : null;
 
//           return {
//             exam_id: exam_id,
//             user_id: user_id,
//             question_id: questionId,
//             option_id: option_id,
//           };
//         });
 
//         fetch("https://newaaas.tdtlworld.com/exam_result/", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(submissions),
//         })
//           .then((response) => {
//             if (response.ok) {
//               Swal.fire(
//                 "Submitted!",
//                 "Your exam has been submitted successfully.",
//                 "success"
//               ).then(() => {
//                 setIsSubmitting(false); // Unset loading state
//                 navigate("/examComplete");
//               });
//             } else {
//               console.error(`Error submitting exam: ${response.statusText}`);
//               setIsSubmitting(false); // Unset loading state
//             }
//           })
//           .catch((error) => {
//             console.error(`Error submitting exam: ${error}`);
//             setIsSubmitting(false); // Unset loading state
//           });
//       }
//     });
//   };
 
//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = seconds % 60;
//     if (minutes == "00" && remainingSeconds == "00") {
//       handleSubmitExam();
//     }
//     return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
//   };
 
//   const handleMouseDown = (event) => {
//     setIsDragging(true);
//   };
 
//   const handleMouseMove = (event) => {
//     if (isDragging) {
//       setPosition({
//         x: position.x + event.movementX,
//         y: position.y + event.movementY,
//       });
//     }
//   };
 
//   const handleMouseUp = (event) => {
//     setIsDragging(false);
//   };
//   const sendRequest = async () => {
//     const image = captureVideoFeed();
//     if (!image) return;
 
//     const imageBlob = dataURLtoBlob(image);
//     if (!imageBlob) return;
 
//     const formData = new FormData();
//     formData.append("image", imageBlob);
//     formData.append("profile", examPhoto);
 
//     const url = "https://newaaas.tdtlworld.com/detect_faces/";
 
//     try {
//       const response = await fetch(url, {
//         method: "POST",
//         body: formData,
//       });
 
//       if (response.ok) {
//         const data = await response.json();
//         if (!data.verfied) {
//           Swal.fire({
//             icon: "error",
//             text: "invalid user",
//           });
//           return;
//         }
//         if (data.number_of_faces >= 2) {
//           Swal.fire("Alert!", "More than 2 People detected", "warning");
//         }
 
//         setNumFaces(data.number_of_faces);
//       }
//     } catch (error) {
//       console.error("Error detecting faces:", error);
//     }
//   };
 
//   return (
//     <div
//       style={{
//         display: "flex",
//         height: "100vh",
//         background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
//       }}
//     >
//       <div
//         style={{
//           flex: "0 0 25%",
//           backgroundColor: "#fff",
//           padding: "20px",
//           overflow: "scroll",
//         }}
//       >
//         <h4>Question List</h4>
//         <ul
//           style={{
//             listStyle: "none",
//             padding: 0,
//             display: "flex",
//             flexWrap: "wrap",
//             gap: "5px",
//           }}
//         >
//           {examData &&
//             examData[0].questions.map((question, index) => (
//               <li
//                 key={index}
//                 style={{
//                   flex: "0 0 33.33%",
//                   maxWidth: "30.33%",
//                   marginBottom: "5px",
//                 }}
//               >
//                 <button
//                   style={{
//                     backgroundColor: attemptedQuestions.includes(
//                       question.question_id
//                     )
//                       ? "#28a745"
//                       : currentQuestionIndex === index
//                       ? "#ffc107"
//                       : "transparent",
//                     color: attemptedQuestions.includes(question.question_id)
//                       ? "#fff"
//                       : "#000",
//                     border: "1px solid #ccc",
//                     padding: "10px",
//                     width: "calc(100% - 20px)",
//                     textAlign: "center",
//                     cursor: "pointer",
//                     borderRadius: "5px",
//                     transition:
//                       "background-color 0.3s ease, border-color 0.3s ease",
//                   }}
//                   onClick={() => setCurrentQuestionIndex(index)}
//                 >
//                   {index + 1}
//                 </button>
//               </li>
//             ))}
//         </ul>
//       </div>
//       <div style={{ flex: "1", padding: "20px" }}>
//         {examData ? (
//           <>
//             <h2 style={{ color: "#fff", textAlign: "center" }}>
//               {examData[0].title}
//             </h2>
//             <div className="row justify-content-center">
//               <div className="col-md-6">
//                 <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//                   <Card.Body style={{ display: "flex" }}>
//                     <div style={{ flex: "1", paddingRight: "20px" }}>
//                       <Card.Title className="text-primary">
//                         Exam Details
//                       </Card.Title>
//                       <ul className="list-unstyled">
//                         <li>
//                           <strong>Start Date:</strong>{" "}
//                           {examData && examData[0].start_date}
//                         </li>
//                         <li>
//                           <strong>Marks:</strong>{" "}
//                           {examData && examData[0].marks}
//                         </li>
//                         <li>
//                           <strong>Description:</strong>{" "}
//                           {examData && examData[0].description}
//                         </li>
//                       </ul>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="col-md-6">
//                 <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//                   <Card.Body>
//                     <Card.Title className="text-primary">
//                       Exam Timing
//                     </Card.Title>
//                     <div className="row">
//                       <div className="col">
//                         <ul className="list-unstyled">
//                           <li>
//                             <strong>Time:</strong> {examData[0].time}
//                           </li>
//                           <li>
//                             <strong>Remaining Time:</strong>{" "}
//                             <span className="text-danger">
//                               {formatTime(remainingTime)}
//                             </span>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="col">
//                         <div
//                           style={{
//                             position: "relative",
//                           }}
//                         >
//                           <div
//                             style={{
//                               position: "relative",
//                               cursor: isDragging ? "grabbing" : "grab",
//                             }}
//                             onMouseDown={handleMouseDown}
//                             onMouseMove={handleMouseMove}
//                             onMouseUp={handleMouseUp}
//                           >
//                             <div
//                               style={{
//                                 position: "absolute",
//                                 top: position.y,
//                                 left: position.x,
//                               }}
//                             >
//                               <Webcam
//                                 audio={false}
//                                 screenshotFormat="image/jpeg"
//                                 videoConstraints={{
//                                   facingMode: "user",
//                                 }}
//                                 ref={webCamRef}
//                                 style={{
//                                   height: "200px",
//                                   width: "250px",
//                                 }}
//                               />
 
//                               <div
//                                 style={{
//                                   position: "absolute",
//                                   top: 0,
//                                   left: 0,
//                                   width: "250px",
//                                   height: "200px",
//                                   backgroundImage:
//                                     "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
//                                   backgroundSize: "cover",
//                                   opacity: 0.3,
//                                 }}
//                               ></div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </div>
//             </div>
//             {examData[0].questions.map((question, index) => (
//               <div
//                 key={question.question_id}
//                 style={{
//                   display: index === currentQuestionIndex ? "block" : "none",
//                 }}
//               >
//                 <Card style={{ marginBottom: "20px" }}>
//                   <Card.Body>
//                     <Card.Title style={{ color: "#007bff" }}>
//                       Question {currentQuestionIndex + 1} (
//                       {question.question_marks} mark(s))
//                     </Card.Title>
//                     <Card.Text style={{ color: "#333" }}>
//                       {question.question_description}
//                     </Card.Text>
//                     <ul style={{ listStyleType: "none", padding: 0 }}>
//                       {question.options.map((option) => (
//                         <li
//                           key={option.option_id}
//                           style={{ marginBottom: "10px" }}
//                         >
//                           <input
//                             type={question.type_id === 1 ? "radio" : "checkbox"}
//                             id={`option_${option.option_id}`}
//                             name={`question_${question.question_id}`}
//                             value={option.option_id}
//                             checked={
//                               selectedOptions[question.question_id] &&
//                               selectedOptions[question.question_id].includes(
//                                 option.option_id
//                               )
//                             }
//                             onChange={() =>
//                               handleOptionChange(
//                                 question.question_id,
//                                 option.option_id
//                               )
//                             }
//                           />
//                           <label
//                             htmlFor={`option_${option.option_id}`}
//                             style={{ marginLeft: "5px", color: "#333" }}
//                           >
//                             {option.option}
//                           </label>
//                         </li>
//                       ))}
//                     </ul>
//                   </Card.Body>
//                 </Card>
//               </div>
//             ))}
//             <div style={{ textAlign: "center" }}>
//               <Button
//                 onClick={handlePreviousQuestion}
//                 disabled={currentQuestionIndex === 0}
//                 style={{
//                   marginRight: "10px",
//                   backgroundColor: "#dc3545",
//                   borderColor: "#dc3545",
//                 }}
//               >
//                 Previous Question
//               </Button>{" "}
//               <Button
//                 onClick={handleNextQuestion}
//                 disabled={
//                   currentQuestionIndex === examData[0].questions.length - 1
//                 }
//                 style={{
//                   marginRight: "10px",
//                   backgroundColor: "#28a745",
//                   borderColor: "#28a745",
//                 }}
//               >
//                 Next Question
//               </Button>{" "}
//               <Button
//                 onClick={handleSubmitExam}
//                 style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
//                 disabled={isSubmitting} // Disable the button while submitting
//               >
//                 {isSubmitting ? (
//                   <Vortex
//                     height="25"
//                     width="25"
//                     color="#ffffff"
//                     ariaLabel="vortex-loading"
//                   />
//                 ) : (
//                   "Submit Exam"
//                 )}
//               </Button>
//             </div>
//           </>
//         ) : (
//           <p style={{ textAlign: "center", color: "#333" }}>
//             Loading exam details...
//           </p>
//         )}
//       </div>
//     </div>
//   );
// }
 