import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { FaEdit, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProfileStudent() {
  const [studentProfile, setStudentProfile] = useState({
    fullName: "",
    email: "",
    bio: "",
    skills: "",
    education: "",
    experience: "",
    photo: "",
  });

  const [editable, setEditable] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    fullName: "",
    email: "",
    bio: "",
    skills: "",
    education: "",
    experience: "",
  });

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const fullName = localStorage.getItem("full_name");
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("user_id");
    const photo = localStorage.getItem("photo");

    setStudentProfile((prevProfile) => ({
      ...prevProfile,
      fullName: fullName || "",
      email: email || "",
      photo: photo || "",
    }));

    // Only fetch profile data if userId is available
    if (userId) {
      setUserId(userId);
      fetchProfileData(userId);
    }
  }, []);

  const fetchProfileData = async (userId) => {
    try {
      const response = await fetch(
        `https://newaaas.tdtlworld.com/fetch_profile/?user_id=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setStudentProfile(responseData);
        setEditedProfile(responseData);
      } else {
        throw new Error("Failed to fetch profile data");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      // Handle the error
    }
  };

  const handleEditToggle = () => {
    setEditable(!editable);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const addStudentProfile = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          ...editedProfile,
        }),
      };

      const response = await fetch(
        "https://newaaas.tdtlworld.com/add_profile/",
        requestOptions
      );
      const data = await response.json();

      if (!data.error) {
        toast.success("Profile added successfully");
        // Update the student profile state with the added profile data
        setStudentProfile(data.profile_data);
      } else {
        toast.error("Failed to add profile");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add profile");
    }
  };

  const handleSaveChanges = async () => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          ...editedProfile,
        }),
      };

      const response = await fetch(
        "https://newaaas.tdtlworld.com/update_profile/",
        requestOptions
      );
      const data = await response.json();

      if (!data.error) {
        toast.success("Profile updated successfully");
        setStudentProfile(editedProfile);
        setEditable(false);
      } else {
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update profile");
    }
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div className="main p-4">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h4 className="card-title mb-0">Student Profile</h4>
              {!editable && (
                <button className="btn btn-primary" onClick={handleEditToggle}>
                  <FaEdit className="mr-1" />
                  Edit
                </button>
              )}
            </div>
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-md-4">
                  {studentProfile.photo && (
                    <img
                      src={studentProfile.photo}
                      alt="Profile"
                      className="img-fluid rounded-circle mb-2"
                      style={{ maxHeight: "200px" }}
                    />
                  )}
                </div>
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      value={studentProfile.fullName}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={studentProfile.email}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="bio">Bio</label>
                    <textarea
                      className="form-control"
                      id="bio"
                      name="bio"
                      value={editedProfile.bio}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="skills">Skills</label>
                    <input
                      type="text"
                      className="form-control"
                      id="skills"
                      name="skills"
                      value={editedProfile.skills}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="education">Education</label>
                    <input
                      type="text"
                      className="form-control"
                      id="education"
                      name="education"
                      value={editedProfile.education}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="experience">Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      id="experience"
                      name="experience"
                      value={editedProfile.experience}
                      onChange={handleInputChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              {editable && (
                <button className="btn btn-primary" onClick={handleSaveChanges}>
                  <FaSave className="mr-1" />
                  Save Changes
                </button>
              )}
              {!userId && (
                <button
                  className="btn btn-primary ml-2"
                  onClick={addStudentProfile}
                >
                  <FaSave className="mr-1" />
                  Add Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
