import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faStop,
  faPlus,
  faTrash,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const McqQuz = ({ category, sub_category, type }) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOptions, setCorrectOptions] = useState([]);
  const [submittedQuestions, setSubmittedQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [marks, setMarks] = useState(0);

  const { transcript, resetTranscript } = useSpeechRecognition();

  useEffect(() => {}, []);

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleMicClick = () => {
    SpeechRecognition.startListening();
    setIsListening(true);
  };

  const handleMicStopClick = () => {
    SpeechRecognition.stopListening();
    setQuestion(transcript);
    resetTranscript();
    setIsListening(false);
  };

  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      try {
        const response = await axios.post(
          "https://newaaas.tdtlworld.com/multiple_details/",
          {
            description: question,
            marks,
            category,
            sub_category,
            type,
            options,
            correct_options: correctOptions, // Use correctOptions array directly
          }
        );

        console.log("Response:", response.data);

        setQuestion("");
        setOptions(["", "", "", ""]);
        setCorrectOptions([]);
        setMarks(0);
        setSelectedFile(null);
        setIsSubmitting(false);
      } catch (error) {
        console.error("Error:", error);
        setIsSubmitting(false);
      }
    }
  };

  const renderSubmittedQuestions = () => (
    <div className="bg-light p-4 mt-5 rounded">
      <h3 className="text-primary text-center mb-4">Submitted Questions</h3>
      {submittedQuestions.map((submittedQuiz, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{submittedQuiz.description}</h5>
            <ul className="list-group mb-3">
              {submittedQuiz.options.map((option, i) => (
                <li key={i} className="list-group-item">
                  {option}
                </li>
              ))}
            </ul>
            <p className="card-text">
              Correct Options: {submittedQuiz.correct_options.join(", ")}
            </p>
            <p className="card-text">Marks: {submittedQuiz.marks}/100</p>

            {submittedQuiz.image && (
              <div className="submitted-image-container mt-3">
                <p className="mb-2">Submitted Image:</p>
                <img
                  src={submittedQuiz.image.url}
                  alt="Submitted Image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);

    const updatedCorrectOptions = correctOptions.filter((_, i) => i !== index);
    setCorrectOptions(updatedCorrectOptions);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const url = window.URL.createObjectURL(file);
      setSelectedFile({
        file,
        url,
      });
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  const handleUploadImage = () => {
    document.getElementById("coverPhoto").click();
  };

  const handleMarkOption = (index, value) => {
    const updatedOptions = [...correctOptions];

    if (updatedOptions.includes(value)) {
      updatedOptions.splice(updatedOptions.indexOf(value), 1);
    } else {
      updatedOptions.push(value);
    }

    setCorrectOptions(updatedOptions);
  };

  const renderForm = () => (
    <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="question"
          style={{ display: "block", marginBottom: "10px" }}
        >
          Question<span style={{ color: "red" }}>*</span>:
        </label>
        <div style={{ position: "relative", marginBottom: "10px" }}>
          <input
            type="text"
            className="form-control"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question..."
            style={{ paddingRight: "40px" }}
          />
          <button
            type="button"
            className={`btn ${isListening ? "btn-danger" : "btn-dark"}`}
            onClick={isListening ? handleMicStopClick : handleMicClick}
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              zIndex: "1",
            }}
          >
            <FontAwesomeIcon icon={isListening ? faStop : faMicrophone} />
          </button>
        </div>
      </div>

      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-md-9">
          <input
            type="file"
            id="coverPhoto"
            name="coverPhoto"
            className="form-control visually-hidden"
            onChange={handleFileInputChange}
            accept="image/*"
          />
          <div
            style={{
              position: "relative",
              height: "200px",
              marginBottom: "10px",
              cursor: "pointer",
              overflow: "hidden",
              border: "1px solid #ccc",
            }}
            onClick={() => document.getElementById("coverPhoto").click()}
          >
            {selectedFile ? (
              <>
                <img
                  src={selectedFile.url}
                  alt="Selected Course Cover"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <button
                  className="btn btn-dark btn-action"
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    zIndex: "1",
                  }}
                  onClick={handleRemoveImage}
                >
                  Remove
                </button>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f0f0f0",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "#888", fontSize: "14px" }}>
                  Click here to upload an Question image
                </span>
              </div>
            )}
            <button
              className="btn btn-dark btn-action"
              style={{
                position: "absolute",
                bottom: "5px",
                right: "5px",
                zIndex: "1",
              }}
              onClick={handleUploadImage}
            >
              Upload
            </button>
          </div>
        </div>

        <div className="col-md-3">
          <input
            type="number"
            className="form-control"
            placeholder="Add Mark"
            id="markOption"
            onChange={(e) => setMarks(Number(e.target.value))}
          />
        </div>
      </div>

      <div className="form-group text-start">
        <label>
          Options<span className="text-danger">*</span>:
        </label>
        <ul className="list-group">
          {options.map((option, index) => (
            <li
              key={index}
              className="list-group-item d-flex align-items-center"
              style={{
                backgroundColor: correctOptions.includes(index)
                  ? "#98FB98"
                  : "#ADD8E6",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <input
                type="checkbox"
                className="form-check-input ml-3"
                checked={correctOptions.includes(option)}
                onChange={() => handleMarkOption(index, option)}
              />
              <input
                type="text"
                className="form-control mr-3"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                style={{
                  border: "2px solid #dee2e6",
                  borderRadius: "8px",
                  padding: "8px",
                  transition: "border-color 0.3s ease-in-out",
                  flex: "1",
                }}
              />
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRemoveOption(index)}
                style={{
                  borderRadius: "8px",
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <button
        type="button"
        className="btn btn-dark mr-2"
        onClick={handleAddOption}
        style={{ marginRight: "15px" }}
      >
        <FontAwesomeIcon icon={faPlus} /> Add Option
      </button>

      <button
        type="button"
        className="btn btn-success"
        onClick={handleSubmit}
        disabled={isSubmitting}
        style={{ marginRight: "15px" }}
      >
        {isSubmitting ? (
          <>
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
            Submitting...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCheck} /> Submit
          </>
        )}
      </button>

      {isSubmitting && (
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => setIsSubmitting(false)}
        >
          <FontAwesomeIcon icon={faTimes} /> Cancel
        </button>
      )}
    </div>
  );

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  return (
    <div>
      <div className="wrapper">
        <div
          className="main p-3"
          style={{
            flex: "1",
            overflow: "auto",
          }}
        >
          <div className="mcq-quiz-container container mt-3">
            {renderForm()}
            {/* {renderSubmittedQuestions()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default McqQuz;
